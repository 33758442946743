import React, {useEffect} from "react";
import {VoteItemComp} from "./style";
import {useNavigate} from "react-router";
import {useState} from "react";
import {methods} from "../../utils/methods/methods";
import {Button, Dialog, DialogContent} from "@mui/material";
import Ballot from "./Ballot";

const VoteItem = ({  
  ideia,
  sendMessageWS,
  handleActiveIdea,
  innerwidth,
  poll_kind,
  ...props
}) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // useEffect(() => {
  //   setOpenDialog(false);
  // }, [ideia]);
  
  return (
    <VoteItemComp>
      <h3 className="nowrap myvote-title">{ideia.title}</h3>
      <span className="line">|</span>
      <h4 className="myvote-icons nowrap">
        Voto:

        <span style={{display: poll_kind == 0 ? 'block' : 'none'}}  className={`material-symbols-sharp ${methods.getIcon(ideia.vote__vote)}`}>
           {methods.getIcon(ideia.vote__vote)}
        </span>

        <span style={{display: poll_kind == 1 ? 'block' : 'none'}} >
           {ideia.vote__vote}
        </span>
        
      </h4>
      <span className="line">|</span>
      <span className="myvote-comment">
        <h4>Comentário: </h4>
        <span>{ideia.vote__comment}</span>
      </span>
      {ideia.is_active && (
        <Button
          onClick={handleOpenDialog}
          variant="contained"
          sx={{
            backgroundColor: "#1B5CFF",
            marginLeft: innerwidth < 800 ? "0" : "auto",
            width: innerwidth < 800 ? "100%" : "fit-content",
            padding: "10 15px",
          }}
        >
          Alterar
        </Button>
      )}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <DialogContent>
          <Ballot idea={ideia} votespage={true}  sendMessageWS={sendMessageWS} editvote={true} poll_kind={poll_kind} />
        </DialogContent>
      </Dialog>
    </VoteItemComp>
  );
};

export default VoteItem;
