import styled, { css } from "styled-components";
import colors from "../../utils/colors.json";

const PageGroupComp = styled.div`
  width: 100%;
  min-height: ${(props) => props.innerheight + "px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: var(--default-padding);
  padding-top: 0;
  background-image: linear-gradient(#ffffffa7, #ffffffa7),
    url("/images/paper-texture.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  .page-title,
  .add-content,
  .btn-container,
  .page-card,
  .pages-container {
    display: flex;
  }

  .add-content{
    max-width: 1500px;
  }

  .page-card{
    padding: var(--default-padding);
    box-sizing: border-box;
    cursor: pointer;
    background-color: ${colors.fcb_blue};
    height: 100px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: end;
    border-radius: 10px;

    p{
      font-family: "CodeNext-Bold";
      font-weight: 500;
    }
  }

  .pages-container{
    flex-direction: column;
    min-width: 50%;
    max-width: 80%;
    margin: 15px;
    gap: var(--default-padding);
  }

  .page-title {
    align-items: center;
    justify-content: end;
    width: 100%;
    height: 120px;
    background-color: black;
    background-image: url("/images/FCB-Magenta-Color.jpg");
    background-size: 100%;
    background-attachment: fixed;
    color: white;
    padding: var(--default-padding);

    h2 {
      font-size: 2.5em;
    }
  }

  @media screen and (max-width: 700px) {
    .pages-container {
      width: 100%;
      max-width: unset;
    }
  }
`;

export { PageGroupComp };
