import React, { useState, useRef } from "react";
import { ContentComp } from "./style.jsx";
import useEditableStore from "../../utils/stores/useEditableStore.jsx";
import ContentEditable from "./ContentEditable.jsx";
import { convertToForm } from "../../utils/utils.jsx";
import makeRequest from "../../utils/methods/makeRequest.js";
import { useParams } from "react-router";
import ArrowsPosition from "../ArrowsPosition/ArrowsPosition.jsx";
import ContentHeader from "../ContentHeader/ContentHeader.jsx";
import LayoutDefault from "./Layouts/LayoutDefault/LayoutDefault.jsx";
import getCsrfToken from "../../utils/methods/getCsrfToken";

const ContentHub = ({ datacontent, autoEdit, ...props }) => {
  const editable = useEditableStore(state => state.editable);
  const [openEditForm, setOpenEditForm] = useState(autoEdit);
  const [content, setContent] = useState(datacontent);
  const [percentCompleted, setPercentCompleted] = useState(null);
  const [images, setImages] = useState(null);
  const [textElement, setTextElement] = useState("Texto");
  const params = useParams();

  const contentObj = useRef({
    // page_path: params.page_path,
  });

  const handleInput = e => {
    contentObj.current[e.target.name] = e.target.value;
  };

  const handleCancelEdit = () => {
    setOpenEditForm(false);
  };

  const handleSaveContent = () => {
    setOpenEditForm(false);
    setContent(contentObj.current);

    var positionData = new FormData();
    var contentData = convertToForm(contentObj.current);

    if (images !== null) {
      contentData.append("image", images);
    }

    if (textElement === "Lista") {
      contentData["text"] = contentData;
    }

    var url = process.env.REACT_APP_SERVER_URL + "/editContent/";
    if (!params.page_path) {
      var path;
      if (window.location.pathname === "/") {
        path = "/";
        contentData.append("page_path", path);
        positionData.append("page_path", path);
      } else {
        path = window.location.pathname.toString().split("/");
        contentData.append("page_path", path[path.length - 1]);
        positionData.append("page_path", path[path.length - 1]);
      }
    } else {
      contentData.append("page_path", params.page_path);
      positionData.append("page_path", params.page_path);
    }

    var onUpload = progressEvent => {
      setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    };

    if (content.content_id) {
      contentData.append("content_id", content.content_id);

      getCsrfToken().then(res => {
        makeRequest(url, contentData, "post", onUpload, res.data)
          .then(res => res.data)
          .then(data => {
            window.location.reload();
          })
          .catch(err => {
            console.log(err);
          });
      });
    } else {
      getCsrfToken().then(res => {
        makeRequest(url, contentData, "post", onUpload, res.data)
          .then(res => res.data)
          .then(data => {
            if (data.Status == "content saved") {
              positionData.append("content_id", data.content_id);
              positionData.append("level", props.id);

              var url = process.env.REACT_APP_SERVER_URL + "/setPosition/";
              getCsrfToken().then(res => {
                makeRequest(url, positionData, "post", '', res.data)
                  .then(res => res.data)
                  .then(data => {
                    window.location.reload();
                  });
              });
            }
            // setContent(data);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  };

  return (
    <ContentComp pagepath={params.page_path} active={1} editable={editable ? 1 : 0} editcontent={openEditForm ? 1 : 0} {...props}>
      {editable && <ContentHeader setOpenEditForm={setOpenEditForm} content={content} />}
      {editable && <ArrowsPosition content={content} params={params} contentid={props.id} {...props} />}

      {/* {openEditForm && editable} */}
      {editable && openEditForm && (
        <ContentEditable
          content={content}
          contentObj={contentObj.current}
          setContent={setContent}
          handleInput={handleInput}
          handleSaveContent={handleSaveContent}
          handleCancelEdit={handleCancelEdit}
          images={images}
          setImages={setImages}
          textElement={textElement}
          setTextElement={setTextElement}
        />
      )}

      {!openEditForm && <LayoutDefault pagepath={params.page_path} content={content} percentCompleted={percentCompleted} />}
    </ContentComp>
  );
};

export default ContentHub;
