import axios from "axios";

const getCsrfToken = () => {
  var url = `${process.env.REACT_APP_SERVER_URL}/csrf/`;
  return axios({
    method: "get",
    url: url,
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default getCsrfToken;
