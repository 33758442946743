import React from "react";
import { icon_set } from "../../utils/icon_set.js";
import makeRequest from "../../utils/methods/makeRequest.js";
import getCsrfToken from "../../utils/methods/getCsrfToken.js";

const ArrowsPosition = ({ contentid, content, params, ...props }) => {
  const handleChangePosition = (e, id, condition) => {
    var url = process.env.REACT_APP_SERVER_URL + "/changePosition/";
    var newPositionData = new FormData();

    if (condition == "up") {
      newPositionData.append("level", id - 1);
    }

    if (condition == "down") {
      newPositionData.append("level", id + 1);
    }

    newPositionData.append("content_id", content.content_id);

    if (!params.page_path) {
      var path;
      if (window.location.pathname === "/") {
        path = "/";
        newPositionData.append("page_path", path);
      } else {
        path = window.location.pathname.toString().split("/");
        newPositionData.append("page_path", path[path.length - 1]);
      }
    } else {
      newPositionData.append("page_path", params.page_path);
    }

    getCsrfToken().then(res => {
      makeRequest(url, newPositionData, "post", '', res.data)
        .then(res => res.data)
        .then(data => {
          if (data.Status == "position changed") {
            window.location.reload();
          }
        })
        .catch(err => {
          console.log(err);
        });
    });
  };

  return (
    <div className="arrows">
      {contentid > 0 && (
        <span
          id={contentid}
          className="arrow-up"
          onClick={e => {
            handleChangePosition(e, contentid, "up");
          }}>
          {icon_set.up}
        </span>
      )}

      {contentid < props.length - 1 && (
        <span
          className="arrow-down"
          onClick={e => {
            handleChangePosition(e, contentid, "down");
          }}>
          {icon_set.down}
        </span>
      )}
    </div>
  );
};

export default ArrowsPosition;
