import {BallotComp} from "./style";
import React, {useEffect, useRef, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {methods} from "../../utils/methods/methods";

const Ballot = ({idea, myvote, sendMessageWS, editvote = false, votespage = false,vote__comment=false, poll_kind , ...props}) => {
  const [voted, setVoted] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [comment, setComment] = useState("");
  const [editComment, setEditComment] = useState(true);
  const [editCommentBtn, setEditCommentBtn] = useState(false);
  const [activeBtn, setActiveBtn] = useState(null);
  const textAreaRef = useRef();

  const [voteId, setVoteId] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.defaultValue = idea.vote__comment ? idea.vote__comment : "";
    }
    setActiveBtn(idea.vote__vote);

    if (editvote) {
      setVoted(false);
    } else {
      setVoted(true);
    }
    setVoteId(idea.vote__id);

    if (idea.vote__comment !== "") {
      setEditComment(false);
    }
    setComment(idea.vote__comment);

  }, [idea.vote__vote]);

  const voteToId = (vote) => {
    switch (vote) {
      case "no":
        return 0;
      case "yes":
        return 1;
      case "love":
        return 2;
    }
    switch (vote) {
      case "vote1":
        return 1;
      case "vote2":
        return 2;
      case "vote3":
        return 3;
      case "vote4":
        return 4;
      case "vote5":
        return 5;   
      case "vote6":
        return 6;           
    }
  };

  const handleConfirmation = (e) => {
    if (e.target.id == "") {
      //setVoteId(myvote.vote);
    } else {
      setVoteId(voteToId(e.target.id));
    }
    setOpenConfirm(true);
    setEditComment(false);
    setEditCommentBtn(false);
    // setVoted(true);
  };

  const handleVote = (e) => {
    sendMessageWS({
      call: "vote_ws",
      data: {
        ideia_id: idea.id,
        vote: voteId,
        comment: comment,
      },
    });
    setOpenConfirm(false);
  };

  return (
    <>
      {!myvote || votespage == true ? (
        <BallotComp className="ballot-list-item" innerheight={innerHeight} editvote={editvote}>
          <div className="idea-infos">
            <h3>{idea.title}</h3>
            <p>{idea.desc}</p>

            <div className={`voting ${voted ? "closed" : ""}`}>
              <span
                onClick={(e) => {
                  setVoted(false);
                }}
                className={`material-symbols-sharp voted ${voted ? "active" : ""}`}
              >
                mail
              </span>
              <div className={`vote-btn ${voted ? "" : "active"}`}>
                {editComment == true ? (
                  <div className="comment-container">
                    <textarea
                      ref={textAreaRef}
                      name=""
                      id=""
                      cols="30"
                      rows="6"
                      placeholder="Escreva aqui seu comentário..."
                      defaultValue={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    ></textarea>
                    {/* {editComment == true && editCommentBtn == true && (
                      <button className="comment-save" onClick={handleConfirmationText}>
                        Salvar comentário
                      </button>
                    )} */}
                  </div>
                ) : (
                  <div
                    className="comment-container"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      setEditComment(true);
                      setEditCommentBtn(true);
                    }}
                  >
                    <p className="title-comment">
                      Meu comentário: <span className="material-symbols-outlined">edit</span>
                    </p>
                    <p className="comment">{comment}</p>
                  </div>
                )}

                <div style={{display: poll_kind == 1 ? 'none' : 'block'}} >
                    <button
                      className={`vote-btn-love ${activeBtn == 2 && "active-btn" || ''}`}
                      id="love"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(2);
                      }}
                    >
                      <span className="material-symbols-sharp">favorite</span> AMEI
                    </button>
                    <button
                      className={activeBtn == 1 && "active-btn" || ''}
                      id="yes"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(1);
                      }}
                    >
                      SIM
                    </button>
                    <button
                      className={activeBtn == 0 && "active-btn" || ''}
                      id="no"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(0);
                      }}
                    >
                      NÃO
                    </button>
                </div>

                <div style={{display: poll_kind == 0 ? 'none' : 'flex'}} >
                    <button
                      className={activeBtn == 1 && "active-btn" || ''}
                      id="vote1"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(1);
                      }}
                    >
                      1
                    </button>

                    <button
                      className={activeBtn == 2 && "active-btn" || ''}
                      id="vote2"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(2);
                      }}
                    >
                      2
                    </button>

                    <button
                      className={activeBtn == 3 && "active-btn" || ''}
                      id="vote3"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(3);
                      }}
                    >
                      3
                    </button>

                    <button
                      className={activeBtn == 4 && "active-btn" || ''}
                      id="vote4"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(4);
                      }}
                    >
                      4
                    </button>

                    <button
                      className={activeBtn == 5 && "active-btn" || ''}
                      id="vote5"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(5);
                      }}
                    >
                      5
                    </button>

                    <button
                      className={activeBtn == 6 && "active-btn" || ''}
                      id="vote6"
                      onClick={(e) => {
                        handleConfirmation(e);
                        setActiveBtn(6);
                      }}
                    >
                      6
                    </button>

                </div>



              </div>
            </div>
          </div>

          <Dialog
            open={openConfirm}
            onClose={() => {
              setOpenConfirm(false);
            }}
          >
            <DialogContent>
              <h1>Confirmação de voto:</h1>
              <p>Voto: { poll_kind == 0 ?  methods.idToVote(voteId) : voteId }</p>
              <p>Comentário: {comment}</p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenConfirm(false);
                }}
              >
                VOLTAR
              </Button>
              <Button variant="contained" onClick={handleVote}>
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </BallotComp>
      ) : null}
    </>
  );
};

export default Ballot;
