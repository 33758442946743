import styled from "styled-components";

const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--default-padding);
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 5px;
  width: 100%;
  color: black;
  font-family: 'CodeNext-Bold';
  z-index: 20;

  ${(props) =>
    props.dragging
      ? `
        border-color: red;
        `
      : `
        border-color: #00000075;
    `}

    @media screen and (max-width: 700px) {
      padding: 0;
      border-width: 1.5px;
      
      input{
        width: 95%;
      }
      label, br{
        display: none;
      }
    }
`;

const DropzoneInput = styled.input`
  width: fit-content;
  height: fit-content;
  padding: 5px;
  border-radius: 10px;
  color: black;
`;

const DropzoneLabel = styled.label`
  padding: 8px 16px;
  width: fit-content;
  text-align: center;
  color: black;
  border-radius: 4px;
  cursor: pointer;
`;

export { DropzoneWrapper, DropzoneInput, DropzoneLabel };
