import React, { useEffect, useState } from "react";
import { PageComp } from "./style";
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import useEditableStore from "../../utils/stores/useEditableStore";
import useAdminPermission from "../../utils/stores/useAdminPermission";
import { createArrayOfContents } from "../../utils/utils";
import makeRequest from "../../utils/methods/makeRequest";
import { useNavigate, useParams } from "react-router";
import AdminBar from "../../components/AdminBar/AdminBar";
import PageHeader from "../../components/PageHeader/PageHeader";
import AddContent from "../../components/AddContent/AddContent";
import getCsrfToken from "../../utils/methods/getCsrfToken";

const Page = ({ ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [pagedata, setPageData] = useState(false);
  // const [pagedata, setPageData] = useState(data);
  // const [contents, setContents] = useState(createArrayOfContents(data.position_set));
  const [contents, setContents] = useState([]);

  const adminPermission = useAdminPermission(state => state.adminPermission);
  const setAdminPermission = useAdminPermission(state => state.setAdminPermission);
  const editable = useEditableStore(state => state.editable);
  const setEditable = useEditableStore(state => state.setEditable);



  const params = useParams();
  const navigate = useNavigate();

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  const checkAuth = () => {
    var url = process.env.REACT_APP_SERVER_URL + "/sso/";
    // fetch(url)
    getCsrfToken()
    .then(res => {
      fetch(url)
        .then(res => {
          return res.json();
        })
        .then(data => {
          if (data.data && data.data.status === 403) {
            navigate("/login");
          } else {
            var userData = JSON.stringify(data);
            window.sessionStorage.setItem("user", userData);
            window.sessionStorage.setItem("user-token", data.token);
            if (data.cms == true) {
              setAdminPermission(true);
            }
          }
        })
        .catch(err => {
          // console.log(err);
        });
    });
  };

  const requestPageData = () => {
    checkAuth();
    var url = process.env.REACT_APP_SERVER_URL + "/returnPage/";
    var data = new FormData();
    data.append("page_path", params.page_path);

    getCsrfToken().then(res => {
      // setCookie("csrftoken", res.data, 1);
      makeRequest(url, data, "post", false, res.data)
        .then(res => res.data)
        .then(data => {
          setPageData(data);
          setContents(createArrayOfContents(data.position_set));
        })
        .catch(err => {
          // console.log(err);
        });
    });
  };

  useEffect(() => {
    requestPageData();
    var edit = window.sessionStorage.getItem("editable");    
    if (edit == "false"){
      edit = false;
    }else{
      edit = true
    }
    setEditable(edit);
  }, []);

  return (
    <PageComp pagepath={params.page_path} innerheight={innerHeight} editable={editable} {...props}>
      {adminPermission && <AdminBar />}
      {pagedata.menu && <Menu isopen={isMenuOpen ? 1 : 0} />}
      {pagedata.title && <PageHeader setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} pagedata={pagedata} />}

      <div className="contents-container">{contents}</div>

      {editable && <AddContent params={params} setContents={setContents} pagedata={pagedata} />}
      {pagedata.footer && <Footer />}
    </PageComp>
  );
};

export default Page;
