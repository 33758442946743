import React, {useEffect, useState, useContext, useRef} from "react";
import {Container} from "./style";
import {useNavigate, useParams} from "react-router";
import Header from "./Header";
import checkSuperUser from "../../utils/methods/checkSuperUser";
import IdeaItem from "./IdeaItem";
import {WebSocketContext} from "../../api/WSContext";
import {IdeaLink} from "./style";

const Vote = ({...props}) => {
  const navigate = useNavigate();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [ideas, setIdeas] = useState([]);
  const [activeIdeas, setActiveIdeas] = useState([]);
  const [rankingList, setRankingList] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const params = useParams();
  const poll_title = params.poll_title;


  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  useEffect(() => {
    if (message && message.data.method == "list_ideas_ws") {
      updateList(message.data.ideias);

    }
    if (message && message.data.method == "vote_ws") {
      updateList(message.data);
    }
    if (message && message.data.method == "list_votes_ws") {
      updateVotes(message.data.votes);
     
    }
    if (message && message.data.method == "vote_ws") {
      updateList(message.data);
    }

  }, [message]);


  const updateList = (list) => {
    list.sort((a, b) => a.id - b.id);
    setIdeas(list);
  };

  const votes=useRef([]);
  const updateVotes = (list) => {
    votes.value = list;
    getList(); 
  };
  const getVote = function(idea_id){
    console.log("ideia_id: "+idea_id);    

    if (votes.value){

      for (let i=0;i<=votes.value.length;i++){
        if (votes.value[i]){
          //console.log(votes.value[i]);
          if (idea_id == votes.value[i].ideia__id){
            return 'block';
          }
        }
      }
    }
    return 'none';
  }

  const getList = () => {
    sendMessageWS({
      call: "list_ideas_ws",
      data: {
        "poll_title":poll_title
      }
    });
  };

  const getVotes = () => {
    sendMessageWS({
      call: "list_votes_ws",
      data: {
        "poll_title":poll_title
      }
    });
  };


  const handleClick = (e) => {
    navigate(`/poll/vote_idea/${e.target.id}`);
  };

  useEffect(() => {
    //getLastJury();
    //getList();    
    getVotes();
  }, []);

  // const getLastJury = () => {
  //   var activeList = [];
  //   ideas.forEach((item) => {
  //     if (item.is_active) {
  //       activeList.push(item);
  //       rankingList.forEach((i) => {
  //         if (i.title === item.title) {
  //           item.votes = i.votes;
  //         }
  //       });
  //     }
  //   });
  //   setActiveIdeas(activeList);
  // };

  // const transformName = (name) => {
  //   var string = name;
  //   string = string.split("@")[0];
  //   string = string.split(".");
  //   return `${string[0]} ${string[1]}`;
  // };

  return (
    <Container {...props} innerheight={innerHeight}>
      <Header ideasLength={activeIdeas.length} title={poll_title} menu={true} />
      <div className="wrapper list-container">
        <div className="list-items">


        {ideas.length > 0 ? (

            ideas.map((idea, i) => {
              return (

              <IdeaLink onClick={handleClick} key={idea.id} id={idea.id} votes={votes} >
                {idea.title} 


                <span className="material-symbols-outlined" id={"ideia_vote_"+idea.id} style={{ 'display': getVote(idea.id) }}  >mail</span>
              </IdeaLink>
              );
            })

          ) : (

            <p key="list_sub_title">Aguarde as ideias para votar.</p>

          ) }
        </div>

      </div>
    </Container>
  );
};

export default Vote;
