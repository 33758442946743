import React, {useEffect, useState} from "react";
import {Container, Header} from "./style";
import {Outlet, useLocation, redirect, useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
//import {Squash as Hamburger} from "hamburger-react";
import makeRequest from "../../../utils/makeRequest";
import getCsrfToken from "../../../utils/getCsrfToken";

const HomePoll = () => {
  const [currentPage, setCurrentPage] = useState();
  const [menuIsOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    var currentStr = location.pathname;
    setCurrentPage(currentStr.split("/")[1]);
  });


  const getUserData = () => {
    var url = process.env.REACT_APP_SERVER_URL + "/sso/";
    makeRequest(url, "", "get")
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 403) {
          navigate("/poll/login");
        } else {
          var userData = JSON.stringify(data);
          window.sessionStorage.setItem("user", userData);
          window.sessionStorage.setItem("user-token", data.token);
          window.sessionStorage.setItem("poll_super", data.poll_super);
          // setCookie('token', userData.token, 30)
          // setTimeout(() => {
          //   navigate("/vote/brcc");
          // }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(() => {
    //document.title = `BRCC`;
    //getUserData();
    //console.log(`***** ${process.env.REACT_APP_ENVIRONMENT} *****`);
  }, []);

  return (
    <Container env={process.env.REACT_APP_ENVIRONMENT}>
      <div className="outlet-container">
        <Outlet></Outlet>
      </div>
    </Container>
  );
};

export default HomePoll;
