import React, { useEffect, useState } from "react";
import { PageGroupComp } from "./style";
import Menu from "../../components/Menu/Menu";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import pageGroup from "../../utils/data/returnPageGroups.json";
import useEditableStore from "../../utils/stores/useEditableStore";
import { useNavigate } from "react-router";

const PageGroup = ({ ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [pagedata, setPageData] = useState(pageGroup.menu[1]);
  const [pages, setPages] = useState();
  const navigate = useNavigate();
  const editable = useEditableStore((state) => state.editable);
  const setEditable = useEditableStore((state) => state.setEditable);

  // const pageObj = useRef({
  //   title: "",
  //   subtitle: "",
  //   image: "",
  //   video: "",
  //   text: "",
  //   assets: "",
  //   direct_link:""
  // });

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  useEffect(() => {
    // setEditable(true);
    createArrayOfPages(pagedata);
  }, []);

  const createArrayOfPages = (array) => {
    var pagesArray = [];
    array.group.map((page, i) => {
      pagesArray.push(
        <div
          onClick={() => {
            navigate(page.page_path);
          }}
          key={i}
          className="page-card"

          style={{backgroundColor: page.status == "Aprovado" && `#c1c1c1`}}
        >
          <h3>{page.page_title}</h3>
          <p>{page.status}</p>
        </div>
      );
    });
    setPages(pagesArray);
  };

  const handleAddPage = (e) => {
    // setContents((contents) => [
    //   <Content
    //     length={pagedata.contents.length}
    //     datacontent={contentObj.current}
    //      key={contents.length - 1}
    //     id={contents.length - 1}
    //   />,
    //   ...contents,
    // ]);
  };

  useEffect(() => {
    console.log(pagedata);
  }, [pagedata]);

  return (
    <PageGroupComp {...props} innerheight={innerHeight}>
      <Header
        header={false}
        menu={true}
        isopen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        theme={"page"}
      />

      <Menu isopen={isMenuOpen ? 1 : 0} />

      {pagedata.group_name && (
        <div className="page-title">
          <h2>Páginas em Aprovação</h2>
        </div>
      )}

      {/* <div className="add-content">
        <div className="add-btn-container">
          <ToggleButton ison={editable} seton={setEditable}/>
          <p>{editable ? "Visão do editor" : "Visão do colaborador"}</p>
        </div>
        <Button
          onClick={handleAddPage}
          icon={"add"}
          label={"Nova Página"}
          color_set={{
            default: colors.fcb_pink,
            hover: colors.fcb_pink_shaddow,
          }}
        />
      </div> */}

      <div className="pages-container">{pages}</div>
      <Footer />
    </PageGroupComp>
  );
};

export default PageGroup;
