import React, {useEffect, useState,useContext, useRef, } from "react";
import {Container} from "./style";
import {useNavigate, useParams} from "react-router";
import Header from "./Header";
import {WebSocketContext} from "../../api/WSContext";
import VoteItem from "./VoteItem";

const MyVotes = ({...props}) => {
  const navigate = useNavigate();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [votes, setVotes] = useState([]);
  const [allIdeas, setAllIdeas] = useState([]);
  const [ideias, setIdeias] = useState([]);
  const [activeIdeas, setActiveIdeas] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const params = useParams();
  const poll_title = params.poll_title;


  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
    setInnerWidth(window.innerWidth);
  });


  const [poll_kind, setPoll_kind] = useState([]);
  useEffect(() => {
    if (message && message.data.method == "my_votes_ws") {
      setIdeias(message.data.ideias);
      setPoll_kind(message.data.poll_kind)
    }
    if (message && message.data.method == "navigate") {
      window.location.reload();
    }

  }, [message]);

  const getList = () => {
    sendMessageWS({
      "call": "my_votes_ws",
      data: {
        "poll_title": poll_title,
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Container {...props} innerheight={innerHeight}>


      <Header menu={true} title={poll_title} />
      <div className="myvotes-container">
        <div className="list-items">
          <h1 className="list-title" key="list_title" >Meus votos:</h1>
          {ideias.length > 0 ? (
            ideias.map((ideia, i) => {
              return (
                <VoteItem
                  key={ideia.id}
                  votes={votes}
                  sendMessageWS={sendMessageWS}
                  innerwidth={innerWidth}
                  ideia={ideia}
                  poll_kind={poll_kind}
                />
              );
            })
          ) : (
            <p key="list_sub_title">Você ainda não tem votos.</p>
          )}
        </div>
      </div>
    </Container>
  );
};

export default MyVotes;
