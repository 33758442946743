import styled, { css } from "styled-components";

const LayoutDefaultComp = styled.div`
  background-color: ${props => (props.editable ? css`#d0d0d09a` : "transparent")};
  padding: ${props => (props.editable ? `var(--default-padding)` : "none")};
  transition: all 0.5s;
  border-radius: 10px;
  flex-grow: 1;
  gap: ${props => (props.editable ? "10px" : 0)};
  flex-direction: ${({ pagepath }) => (pagepath === "aniversariantes" ? `row-reverse` : `column`)};
  justify-content: ${({ pagepath }) => (pagepath === "aniversariantes" ? `left` : `center`)};
  display: flex;

  .default_subtitle {
    margin: 6px 0;
  }

  .default_title {
    margin: 15px 0 6px 0;
  }

  .default_title h2 {
    font-family: ${({ pagepath }) => (pagepath === "aniversariantes" ? `CodeNext-Bold` : `CodeNext-Bold`)};
    font-size: ${({ pagepath }) => (pagepath === "aniversariantes" ? `20px;` : `26px`)};
  }

  .default_subtitle h4 {
    font-size: 18px;
    font-family: ${({ pagepath }) => (pagepath === "aniversariantes" ? `CodeNext-Bold` : "CodeNext-Bold")};
    font-size: ${({ pagepath }) => (pagepath === "aniversariantes" ? `18px;` : `18px;`)};
  }

  .default_text p {
    font-size: 18px;
    font-family: "CodeNext-Book";
  }

  .default_download,
  .default_title,
  .default_embed,
  .default_items {
    display: flex;
  }

  .default_title,
  .default_items,
  .default_embed {
    gap: 10px;
    flex-direction: column;
  }

  .default_embed {
    width: 100%;
    textarea {
      width: 100%;
    }
    * {
      width: 100%;
    }
  }

  .default_download {
    flex-direction: row;
    width: 100%;
    gap: 10px;
    justify-content: start;
    margin: ${props => (props.editable ? "10px 0" : "10px 0 25px 0")};

    a {
      text-decoration: none;
    }
  }

  .disclaimer {
    font-size: 13px;
  }

  @media screen and (max-width: 700px) {
    min-width: 73vw;
    max-width: 95vw;

    .default_download {
      a {
        width: 100%;
      }
    }
  }
`;

export { LayoutDefaultComp };
