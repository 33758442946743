import ContentHub from "../components/Content/ContentHub";

const toggleMenu = (setState, state) => {
  setState(!state);
};

const createArrayOfContents = (array) => {
  var contentsArray = [];
  array.sort((a, b) => (a.level > b.level ? 1 : -1));
  array.map((content, i) => {
    contentsArray.push(<ContentHub key={i} length={array.length} id={i} datacontent={content} />);
  });
  return contentsArray;
};

const convertToForm = (data) => {
  var formdata = new FormData();
  for (var key in data) {
    if (data[key] !== undefined && data[key] !== null) {
      formdata.append(key, data[key]);
    }
  }
  return formdata;
};

export {toggleMenu, createArrayOfContents, convertToForm};
