import React from "react";
import Header from "../../components/Header/Header";
import { useParams } from "react-router";

const PageHeader = ({ pagedata, isMenuOpen, setIsMenuOpen }) => {
  const params = useParams();

  return (
    <div className="page-title">
      <Header header={pagedata.header} menu={pagedata.menu} isopen={isMenuOpen} setIsOpen={setIsMenuOpen} theme={"page"} />

      {params.page_path !== "aniversariantes" && <h2>{pagedata.title}</h2>}
      {params.page_path == "aniversariantes" && <p className="title-parabens">Feliz Aniversário</p>}
    </div>
  );
};

export default PageHeader;
