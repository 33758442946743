import styled from "styled-components";
import colors from "../../utils/colors.json";

const Container = styled.div`
  min-width: 100%;
  position: absolute;
  min-height: ${(props) => props.innerheight + "px"};
  box-sizing: border-box;
  background-image: linear-gradient(#ffffff96, #ffffff96), url("/images/paper-texture.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    -webkit-tap-highlight-color: transparent;
  }

  .wrapper {
    position: relative;
    width: 35%;
    min-height: ${(props) => props.innerheight + "px"};
  }

  h1 {
    padding: 0 20px;
    box-sizing: border-box;
  }


  .notification {
    width: 100%;
    text-align: center;
  }
  .list {
    display: flex;
    min-height: 50vh;
    padding-top: 20px;
  }

  .list-ideas {
    gap: 50px !important;
  }

  .list-items {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    .add-icon {
      background-color: ${colors.fcb_blue};
      width: 40px;
      height: 40px;
      line-height: 1em;
      color: white;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
      margin-top: 10px;

      &:active {
        transform: scale(0.9);
      }
    }
  }

  .ballot-list-item {
    border-bottom: 1px solid gray;
  }

  .ranking-container {
    display: flex;
    justify-content: start;
    overflow-x: scroll;
    padding: 30px 0;
    width: 100vw;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ranking-table {
    position: sticky;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    padding: 0 50px;
    margin: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
    align-self: flex-start;

    .empty {
      background-color: transparent;
    }

    .header-jury {
      text-transform: capitalize;
    }
    .votes-cell {
      background-color: transparent;
    }

    th {
      background-color: #ffe554b3;
    }
    td,
    th {
      text-align: center;
      padding: 20px;
      white-space: nowrap;
    }

    tr:nth-child(even) {
      background-color: #dddddd40;
    }
  }

  .votes-total,
  .votes-total p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .list-title {
    font-size: 1.5em;
    margin-top: 15px;
    padding-left: 5px;
  }

  .list-sortear-btn {
    background-color: grey;
    width: fit-content;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 50px;
    margin: 15px 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    color: white;
    transition: all 0.5s ease;
  }

  .btn-disclaimer {
    margin-bottom: 35px;
  }

  .list-sortear-btn:active {
    transform: scale(0.9);
  }

  .votes-total p {
    border: 1px solid black;
    padding: 5px;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
  }

  .list-jury {
    padding: 20px 5px;
    margin-top: 10px;
    box-sizing: border-box;
  }

  .list-jury-item {
    margin-top: 5px;
  }

  .list-jury-title {
    background-color: #1759ff50;
    padding: 5px;
    box-sizing: border-box;
    font-family: "CodeNext-Bold";
  }

  .list-last-jury p {
    text-transform: capitalize;
  }

  .myvotes-container {
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 1000px) {
    .wrapper {
      width: 90%;
    }
  }

  @media screen and (min-width: 1700px) {
    .ranking-container {
      justify-content: center;
    }
  }
`;

const BallotComp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .idea-description {
    width: 50%;
  }

  .voting {
    display: flex;
    height: fit-content;
    flex-direction: column;
    h3,
    p {
      margin: 0;
    }

    p {
      margin-bottom: 20px;
    }
    transition: all 0.5s ease;
  }

  .voting.closed {
    height: 150px;
  }

  .idea-infos {
    box-sizing: border-box;
    padding-bottom: 10px;
  }
  .idea-infos,
  .vote-btn {
    height: 100%;
    display: flex;
    flex-direction: column;

    h3,
    p {
      margin: 0;
    }

    p, button {
      margin-bottom: 20px;
      margin:5px;
    }
  }

  .vote-number {
    font-family: "CodeNext-Bold";
  }

  .voting {
    gap: 10px;
    position: relative;
    button {
      width: 100%;
      border: 0px;
      padding: 20px;
      box-sizing: border-box;
      font-size: 1.5em;
      border-radius: 10px;
      font-family: "CodeNext-Bold";
      color: white;
      cursor: pointer;
      transition: all 0.5s ease;

      :nth-child(1) {
        background-color: #7a7a7a;
      }      
      :nth-child(2) {
        background-color: #7a7a7a;
      }
      :nth-child(3) {
        background-color: #7a7a7a;
      }
      :nth-child(4) {
        background-color: #7a7a7a;
      }
      :nth-child(5) {
        background-color: #7a7a7a;
      }
      :nth-child(6) {
        background-color: #7a7a7a;
      }                    
    }

    button:active {
      transform: scale(0.95);
    }
  }

  textarea {
    resize: none;
    
    background-color: transparent;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid gray;
    border-radius: 5px;
    outline: 0;
    font-family: "CodeNext-Book";
    font-size: 1em;
  }

  .voted {
    font-size: 150px;
    text-align: center;
    cursor: pointer;
    color: ${colors.fcb_blue_shaddow};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: all 0.4s ease;
    z-index: 0;
    transform: scale(0.1);
  }

  .vote-btn {
    gap: 10px;
    opacity: 0;
    z-index: 0;
    transform: scale(0.1);
    transition: all 0.4s ease;
  }

  .active {
    transform: scale(1);
    opacity: 1;
    z-index: 5;
  }

  .visible {
    top: 0;
  }

  .vote-btn-love {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active-btn {
    border: 4px solid ${colors.fcb_blue_shaddow} !important;
  }

  .comment-container {
    background-color: #d4d4d49a;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    width: 100%;
  }

  .comment-save {
    background-color: ${colors.fcb_blue_shaddow} !important;
    width: fit-content !important;
    font-size: 16px !important;
    text-transform: uppercase;
    margin: 0 auto;
  }

  .title-comment {
    font-size: 18px;
    font-family: "CodeNext-Bold";
    margin-bottom: 10px !important;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 30px;
    }
  }

  .comment {
    margin: 0;
  }

  ${(props) =>
    props.editvote &&
    `
    .voting  button{
      width: 100%;
      border: 0px;
      padding: 10px;
      box-sizing: border-box;
      font-size: 1.2em;
      border-radius: 5px;
      font-family: "CodeNext-Bold";
      color: white;
      cursor: pointer;
      transition: all 0.5s ease;

      :nth-child(1) {
        background-color: #7a7a7a;
      }      
      :nth-child(2) {
        background-color: #7a7a7a;
      }
      :nth-child(3) {
        background-color: #7a7a7a;
      }
      :nth-child(4) {
        background-color: #7a7a7a;
      }
      :nth-child(5) {
        background-color: #7a7a7a;
      }   
      :nth-child(6) {
        background-color: #7a7a7a;
      }           
  }
  .comment-save {
    width: 100% !important;
    font-size: 16px !important;
  }

  .comment-container {
   
  }

  @media screen and (max-width: 800px) {
    .comment-container {
      width: 100%;
    }
  }

  `}
`;

const DialogContainer = styled.div`
  position: absolute;
  width: 100%;
  top: -100%;
  background-color: #ffffff;
  box-shadow: 3px 2px 23px -4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  gap: 10px;
  transition: top 0.5s ease;

  input {
    font-size: 1.2em;
    border-radius: 5px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 2px solid #dadada;
    font-family: "CodeNext-Book";
  }

  textarea {
    font-family: "CodeNext-Book";
    border-radius: 5px;
    font-size: 1.2em;
    border: 2px solid #dadada;
  }

  h3 {
    padding: 0;
    margin: 0;
    color: #444444;
    display: flex;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  .newiadea-btn {
    background-color: ${colors.fcb_blue};
    border: 0;
    font-family: "CodeNext-Bold";
    padding: 10px;
    border-radius: 5px;
    font-size: 1.2em;
    color: white;
    box-sizing: border-box;
    cursor: pointer;
  }
`;

const IdeaLink = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #c4c4c4b2;
  padding: 10px;
  box-sizing: border-box;
  font-family: "GothamMedium";
  font-size: 18px;
  text-transform: capitalize;
  width: 100%;
  text-decoration: none;
  color: #1b1b1b;

  &&:hover {
    background-color: #c4c4c47b;
  }
`;

const VoteItemComp = styled.a`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: start;
  gap: 30px;
  border-bottom: 2px solid #c4c4c4b2;
  padding: 10px;
  box-sizing: border-box;
  font-family: "GothamMedium";
  font-size: 18px;
  text-transform: capitalize;
  width: 100%;
  text-decoration: none;
  color: #1b1b1b;

  .line {
    font-family: "CodeNext-Light";
    font-size: 30px;
    color: gray;
  }
  .material-symbols-outlined {
    margin-left: auto;
  }

  .myvote-icons {
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
    max-width: 150px;
  }

  .nowrap {
    max-width: 200px;
  }
  .border-right {
    padding-right: 5px;
    border-right: 1px solid black;
  }

  .myvote-comment {
    min-width: 300px;
    max-width: 600px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 0;
    align-items: start;
    justify-content: start;

    .myvote-icons {
      margin-bottom: 0;
    }

    .line {
      display: none;
    }
  }
`;

const TableComments = styled.div`
  padding: 10px;
  width: 60%;

  .table-print {
    width: 100% !important;
  }

  h4 {
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;
  }

  .table-header-comments,
  .table-header-comments a {
    display: flex;
    align-items: center;
  }

  .table-header-comments {
    justify-content: space-between;
    background-color: ${colors.fcb_pink};
    color: white;
    padding: 5px 10px;
  }

  .vote-type {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 0;
    margin: 0;
    border-bottom: 1px solid #bfbfbf;
  }

  .comment-line {
    width: 100% !important;
    text-align: start;
    padding: 15px;
    line-height: 1.5em;
    box-sizing: border-box;
    border-bottom: 1px solid #9d9d9d;
  }

  .comment-line:nth-child(even) {
    background-color: #ddddddcd;
  }
`;

const HeaderBRCC = styled.div`
  width: 100%;
  h2 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.fcb_blue_shaddow};
    color: white;
    padding: 20px;
    box-sizing: border-box;

    span {
      font-size: 0.8em;
      transition: all 0.5s ease;
      cursor: pointer;
      transform: rotate(0deg);
    }

    span.active {
      transform: rotate(360deg);
    }
  }
  .header-fcb {
    background-color: black;
    height: 40px;
    padding: 10px;
    /* box-sizing: border-box; */
    display: flex;
    justify-content: end;
    align-items: center;
    img {
      height:  15px;
      margin-right: auto;
    }

    a {
      font-family: "CodeNext-Bold";
      color: white;
      padding: 0 5px;
      text-decoration: none;
      font-size: 14px;
      text-transform: uppercase;
    }

    a:hover {
      color: ${colors.fcb_yellow};
    }
  }

    .header-margin-bottom{
      margin-bottom:35px;
    }

  .active-path {
    background-color: ${colors.fcb_blue_shaddow};
    padding: 5px 10px !important;
    border-radius: 50px;
    box-sizing: border-box;
  }
  
  .vote-notification {
    background-color: #ff6200;
    padding: 2px 5px;
    box-sizing: border-box;
    border-radius: 50px;
    margin-left: 2px;
  }
`;

const PrintComp = styled.div`
  tr {
    border: 1px solid black;
  }
`;

export {
  Container,
  BallotComp,
  IdeaLink,
  VoteItemComp,
  DialogContainer,
  TableComments,
  PrintComp,
  HeaderBRCC,
};
