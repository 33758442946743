import styled from "styled-components";
 
const LoginComp = styled.div`
  width: 100%;
  position: absolute;
  min-height: ${(props) => props.innerheight + "px"};
  box-sizing: border-box;
  background-image: url("/images/fcbhall.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: var(--default-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .login-welcome,
  .login-contents {
    display: flex;
  }

  .login-welcome {
    gap: 50px;
    position: relative;
    height: 80vh;
    justify-content: center;
    flex-direction: column;

    .login-welcome-h2 {
      /* position: sticky; */
      white-space: nowrap;
      /* text-align: left;
      align-self: start;
      margin-top: 25%; */
      font-size: 3em;
      color: white;
      font-family: "NVRFNSHD-Display";
    }
  }

  .add-btn{
    color: black;
  }

  .login-contents {
    position: relative;
    flex-direction: column;
    gap: var(--default-padding);
    flex: 3;
    height: 100%;
    /* overflow-y: scroll ; */
  }

  .fixed {
    position: absolute;
    bottom: 0;
  }

  .header-title h1 {
    font-size: 1em !important;
  }

  @media screen and (max-width: 700px) {
    .login-welcome-h2 {
      font-size: 3em !important;
    }

    .login-welcome {
      flex-direction: column;
      width: 100%;
    }
  }

  @media screen and (min-width: 1800px) {
    .login-welcome {
      /* width: 80%;
      max-width: 1500px; */
    }
  }
`;

export { LoginComp };
