import styled from "styled-components";

export const ListComp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;

  .close-item path {
    font-weight: 600;
    cursor: pointer;
    /* pointer-events: none; */
  }
`;
