import React from "react";
import { HeaderComp } from "./style";
import MenuButton from "../MenuButton/MenuButton";
import Button from "../Button/Button";
import { useNavigate } from "react-router";
import colors from "../../utils/colors.json";
import Search from "../Search/Search";

const Header = ({ header, menu, isopen, login, setIsOpen, handleLogin, ...props }) => {

  return (
    <HeaderComp header={header} {...props}>
      {header &&  (
        <div className="header-container">
          <a href="/">
            <img
              className="header-logo"
              src={props.theme == "page" ? "/logos/fcb.png" : "/logos/fcb-black.png"}
            />
            <h1 className="header-title">/Busca</h1>
          </a>
        </div>
      )}

      {header && !login && <Search theme={props.theme} />}

      {menu && (
        <div className="menu-btn-container">
          <MenuButton header={header} {...props} isopen={isopen ? 1 : 0} setIsOpen={setIsOpen} />
        </div>
      )}

      {login && (
        <Button
          onClick={handleLogin}
          className={"add-btn"}
          label="Entrar"
          color_set={{ default: "#000", hover: colors.fcb_orange }}
        />
      )}
    </HeaderComp>
  );
};

export default Header;
