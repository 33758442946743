import React, {useEffect, useState, useContext} from "react";
import {Container} from "./style";
import {useNavigate, useParams} from "react-router";
import Header from "./Header";
import checkSuperUser from "../../utils/methods/checkSuperUser";
import IdeaItem from "./IdeaItem";
import {WebSocketContext} from "../../api/WSContext";

const Poll = ({...props}) => {
  const navigate = useNavigate();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [ideas, setIdeas] = useState([]);
  const [activeIdeas, setActiveIdeas] = useState([]);
  const [rankingList, setRankingList] = useState([]);  
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const params = useParams();
  const poll_title = params.poll_title;

  var jury;
  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });


  useEffect(() => {
    if (message && message.data.method == "list_full_ideas_ws") {
      updateList(message.data.ideias);
    }
    if (message && message.data.method == "switch_idea_ws") {
      updateList(message.data.ideias);
    }
    if (message && message.type == "room_update") {
      getList();
    }
  }, [message]);

  const updateList = (list) => {
    list.sort((a, b) => a.id - b.id);
    setIdeas(list);
  };

  const handleShuffle = () => {
    sendMessageWS({
      call: "switch_idea_ws",
      data: {
        ideia_id: "shuffle",
        "poll_title":poll_title
      },
    });
  };

  const handleActiveIdea = (id) => {
    sendMessageWS({
      call: "switch_idea_ws",
      data: {
        ideia_id: id,
        "poll_title":poll_title
      },
    });
  };

  const getList = () => {
    sendMessageWS({
      call: "list_full_ideas_ws",
      data: {
        "poll_title":poll_title
      }
    });

  };

  useEffect(() => {
    getList();
  }, []);

  const getLastJury = () => {
    var activeList = [];
    //console.log("Ideas: "+ideas)
    ideas.forEach((item) => {
      if (item.is_active) {
        activeList.push(item);
        rankingList.forEach((i) => {
          if (i.title === item.title) {
            item.votes = i.votes;
          }
        });
      }
    });
    setActiveIdeas(activeList);
  };
  useEffect(() => {
    getLastJury();
  }, [ideas]);


  const transformName = (name) => {
    var string = name;
    string = string.split("@")[0];
    string = string.split(".");
    return `${string[0]} ${string[1]}`;
  };

  return (
    <Container {...props} innerheight={innerHeight}>
      <Header ideasLength={activeIdeas.length} title={poll_title} />
      <div className="wrapper list-container">
        <div className="list-items">

          <h4 className="list-sortear-btn" onClick={handleShuffle}>
            Sortear idéia para votação
            <span className="material-symbols-outlined">sync</span>
          </h4>
          <small className="btn-disclaimer"> &#40;apenas idéias sem voto&#41; </small>

          {/* <h2 className="list-title">Ative idéias para votação:</h2> */}

          {ideas.length > 0 &&
            ideas.map((idea, i) => {
              return (
                <IdeaItem
                  id={idea.id}
                  idea={idea}
                  key={idea.id}
                  ideaid={idea.id}
                  handleActiveIdea={handleActiveIdea}
                />
              );
            })}
        </div>
        <div className="list-jury">
          {activeIdeas.length > 0 && <h4>Jurados que restam votar:</h4>}
          {activeIdeas &&
            activeIdeas.map((activeIdea, i) => {
              return (
                <div className="list-jury-item" key={activeIdea.id}>
                  <p className="list-jury-title">{activeIdea.title}</p>
                  <div className="list-last-jury">
                    {activeIdea.pending_jury.map((j, i) => {
                      return <p key={i}>{transformName(j.email)}</p>;
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Container>
  );
};

export default Poll;
