import React, { useEffect, useState } from "react";
import { MenuComp, MenuItem } from "./style";
import { useNavigate } from "react-router";
import makeRequest from "../../utils/methods/makeRequest.js";
import getCsrfToken from "../../utils/methods/getCsrfToken.js";

const Menu = ({ isopen, ...props }) => {
  const navigate = useNavigate();
  const [pagegroups, setPagegroup] = useState();
  const [username, setUsername] = useState("");

  const getMenuList = () => {
    var url = process.env.REACT_APP_SERVER_URL + "/returnPageGroups/";

    getCsrfToken().then(res => {
      makeRequest(url, "", "post", false, res.data)
        .then(res => res.data)
        .then(data => {
          if (data.logoff) {
            // navigate("/login");
          } else {
            setPagegroup(data);
          }
        })
        .catch(err => {
          // console.log(err);
        });
    });
  };

  const getUserName = () => {
    if (window.sessionStorage.getItem("user")) {
      setUsername(JSON.parse(window.sessionStorage.getItem("user")).name);
    }
  };

  useEffect(() => {
    getMenuList();
  }, []);

  useEffect(() => {
    getUserName();
  });

  const handleLogout = () => {
    // window.sessionStorage.removeItem("user");
    // document.cookie = "sessionid" +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    navigate("/login");
  };

  return (
    <MenuComp isopen={isopen} {...props}>
      <h4 id="logout">
        Olá, <span id="user_name">{username}</span> <a onClick={handleLogout}>Sair</a>
      </h4>
      {pagegroups &&
        pagegroups.map((item, i) => {
          return (
            <MenuItem key={i}>
              {item.group_name}
              <div className="subitem-container">
                {item.page_set.map((subitem, i) => {
                  return (
                    <a key={i} className="menu-subitem" href={`/${item.group_name}/${subitem.page_path}`}>
                      {subitem.title}
                    </a>
                  );
                })}
              </div>
            </MenuItem>
          );
        })}
    </MenuComp>
  );
};

export default Menu;
