import React from "react";
import { ImageComp } from "./style";

const Image = ({ edit, src, pagepath, type, title,  handleRemoveImage, subtitle, cta, caption, ...props }) => {
  return (
    <ImageComp edit={edit} pagepath={pagepath} type={type} src={src} title={title} caption={caption} {...props}>
      {type !== "cover" && pagepath !== "aniversariantes" && <img loading="lazy" src={src} alt={props.alt} />}
      {title && <h2> {title} </h2>}
      {subtitle && <p> {subtitle} </p>}
      {caption && <p> {caption} </p>}
      {edit && (
        <div onClick={handleRemoveImage} className="mask-delete">
          <span class="material-symbols-outlined">delete</span>
        </div>
      )}
    </ImageComp>
  );
};

export default Image;
