import ReactDOM from 'react-dom';
import "./index.css";
import {WebSocketProvider} from "./api/WSContext";
import Home from "./pages/Home/Home";
import Page from "./pages/Page/Page";
import PageGroup from "./pages/PageGroup/PageGroup";
import Login from "./pages/Login/Login";
import ErrorPage from "./pages/ErrorPage";
import Ranking from "./pages/BRCC/Ranking";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import BRCCHome from "./pages/BRCC/BRCCHome";
import Poll from "./pages/BRCC/Poll";
import PollList from "./pages/BRCC/PollList";
import Idea from "./pages/BRCC/Idea";
import FullIdea from "./pages/BRCC/FullIdea";
import BRCCLogin from "./pages/BRCC/BRCCLogin";
import MyVotes from "./pages/BRCC/MyVotes";
import VoteIdea from './pages/BRCC/VoteIdea';
import Vote from './pages/BRCC/Vote';
import HomePoll from './pages/BRCC/HomePoll/HomePoll'

import { createRoot } from 'react-dom/client';
//import { Ballot } from '@mui/icons-material';

const router = createBrowserRouter(
  [
  

  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:group/:page_path",
    element: <Page />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/page-group",
    element: <PageGroup />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/vote/brcc",
    element: <BRCCHome />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:group/:page_path",
    element: <Page />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/paginas-aprovacao",
    element: <PageGroup />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  //--------- BRCC BRCR -----------//

  {  path: "/poll",
    element:  (
      <WebSocketProvider>
        <HomePoll />
      </WebSocketProvider>
    ),
    children: [
    
            {
              path: "/poll/login",
              element: <BRCCLogin />,
              errorElement: <ErrorPage />,
            },
            {
              path: "/poll/list",
              element: <PollList />,
              errorElement: <ErrorPage />,
            },
            {
              path: "/poll/:poll_title",
              element: <Poll />,
              errorElement: <ErrorPage />,
            },
            {
              path: "/poll/ranking/:poll_title",
              element: <Ranking />,
              errorElement: <ErrorPage />,
            },
            {
              path: "/poll/full_idea/:ideia_id",
              element: <FullIdea />,
              errorElement: <ErrorPage />,
            },
            {
              path: "/poll/vote_idea/:ideia_id",
              element: <VoteIdea />,
              errorElement: <ErrorPage />,
            },
            {
              path: "/poll/vote/:poll_title",
              element: <Vote />,
              errorElement: <ErrorPage />,
            },
            {
              path: "/poll/my-votes/:poll_title",
              element: <MyVotes />,
              errorElement: <ErrorPage />,
            },
        ]
    }
  //--------- BRCC BRCR -----------//  
]);

const root = createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);