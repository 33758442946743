import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ProgressBar from "../../../ProgressBar/ProgressBar.jsx";
import Image from "../../../Image/Image.jsx";
import Button from "../../../Button/Button.jsx";
import colors from "../../../../utils/colors.json";
import { LayoutDefaultComp } from "./style.jsx";
import useEditableStore from "../../../../utils/stores/useEditableStore.jsx";

const LayoutDefault = ({ pagepath, content, percentCompleted }) => {
  const editable = useEditableStore(state => state.editable);
  const navigate = useNavigate();
  const embedRef = useRef();
  const [textComponent, setTextComponent] = useState("Texto");

  const handleLink = () => {
    if (content.content_direct_link == "https://busca.fcbbrasil.com.br/vote/brcc") {
      navigate("/vote/brcc");
    } else {
      window.open(content.content_direct_link, "_blank");
    }
  };

  useEffect(() => {
    if (content.content_embed) {
      embedRef.current.innerHTML = content.content_embed;
    }

    try {
      var contentJSON = JSON.parse(content.content_text);

      if (contentJSON && typeof contentJSON === "object") {
        setTextComponent("Lista");
      }
    } catch (error) {
      setTextComponent("Texto");
    }
  }, [content]);

  return (
    <LayoutDefaultComp
      pagepath={pagepath}
      editable={editable}
      // className="wrapper"
      onClick={content.content_direct_link !== null ? handleLink : null}
      style={{ cursor: content.content_direct_link !== null ? "pointer" : "unset" }}>
      {percentCompleted && <ProgressBar percentage={percentCompleted} />}

      <div className="default_texts">
        {content.content_title && (
          <div className="default_title">
            <h2>{content.content_title}</h2>
          </div>
        )}
        {content.content_subtitle && (
          <div className="default_subtitle">
            <h4>{content.content_subtitle}</h4>
          </div>
        )}

        {textComponent === "Texto" && (
          <div className="default_text">
            <p>{content.content_text}</p>
          </div>
        )}

        {textComponent === "Lista" &&
          JSON.parse(content.content_text).map(item => {
            if (item.content_link !== "") {
              return (
                <li className="text_list">
                  <a href={item.content_link} target="_blank" rel="noopener noreferer">
                    {item.content_text}
                  </a>
                </li>
              );
            } else {
              return <li className="text_list">{item.content_text}</li>;
            }
          })}
      </div>

      {content.content_image && (
        <div className="default_image">
          <Image pagepath={pagepath} src={process.env.REACT_APP_MEDIA_URL + content.content_image} />
          {/* <Image pagepath={pagepath} src={content.content_image} /> */}
        </div>
      )}

      {content.content_embed && <div ref={embedRef} className="default_embed"></div>}

      {content.content_assets && (
        <div className="default_download">
          <a target="_blank" href={process.env.REACT_APP_MEDIA_URL + content.content_assets}>
            <Button icon={"download"} label={"  BAIXAR  "} color_set={{ default: colors.fcb_blue }} />
          </a>
          <p className="disclaimer">Todos os documentos, informações, imagens e dados dispostos nos materiais deste site são propriedade da FCB Brasil e não devem ser compartilhados com outras pessoas e/ou empresas, sob pena das medidas legais cabíveis.</p>
        </div>
      )}
    </LayoutDefaultComp>
  );
};

export default LayoutDefault;
