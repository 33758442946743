import React, {useEffect, useState, useRef} from "react";
import {Container} from "./style";
import {useNavigate} from "react-router";
import Header from "./Header";
import makeRequest from "../../utils/methods/makeRequest";
import colors from "../../utils/colors.json";
import Button from "../../components/Button/Button";


const BRCCLogin = ({...props}) => {
  const navigate = useNavigate();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  const handleLogin = () => {
    window.sessionStorage.setItem("origin", "brcc");
    var url = process.env.REACT_APP_SERVER_URL + "/auth/sign_in";
    window.location.href = url;
  };

  // const getUserData = () => {
  //   var url = process.env.REACT_APP_SERVER_URL + "/sso/";
  //   makeRequest(url, "", "get")
  //     .then((res) => res.data)
  //     .then((data) => {
  //       if (data.status === 403) {
  //         navigate("/vote/login");
  //       } else {
  //         var userData = JSON.stringify(data);
  //         window.sessionStorage.setItem("user", userData);
  //         window.sessionStorage.setItem("user-token", data.token);
  //         window.sessionStorage.setItem("poll_super", data.poll_super);
  //         // setCookie('token', userData.token, 30)
          
  //         // setTimeout(() => {
  //         //   navigate("/vote/brcc");
  //         // }, 1000);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //  // getUserData();


  // }, []);

  return (
    <Container {...props} innerheight={innerHeight}>
      <Header menu={false} />
      <div className="login-container">
        <h1>BRCC / BRCR - Entre para votar</h1>
        <Button
          onClick={handleLogin}
          className={"add-btn"}
          label="Entrar"
          color_set={{default: "#000", hover: colors.fcb_orange}}
        />
      </div>
    </Container>
  );
};

export default BRCCLogin;
