import styled, { css } from "styled-components";
import colors from "../../utils/colors.json";

const ContentComp = styled.div`
  position: relative;
  min-width: 350px;
  min-height: 50px;
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: all 0.2s ease;

  ${({ pagepath }) => (pagepath == "aniversariantes" ? "width: auto" : "width: 100%")};

  /* padding: ${props => (props.editable ? `var(--default-padding)` : 0)}; */

  /* .content_title {
    margin: 15px 0 6px 0;

    h2 {
      font-size: ${({ pagepath }) => pagepath == "aniversariantes" && `20px;`};
      font-family:  ${({ pagepath }) => pagepath == "aniversariantes" && `CodeNext-Bold`}
    }
  } */

  /* .content_subtitle h4, .content_text p{
    font-size: ${({ pagepath }) => pagepath == "aniversariantes" && `18px;`};
    font-family:  ${({ pagepath }) => pagepath == "aniversariantes" && `CodeNext-Book`}
  } */
  
  .label-field {
    font-size: 20px;
  }

  * {
    margin: 0;
  }

  .content_download,
  .content-header,
  .content_title,
  .content_embed,
  .content_items,
  .arrows,
  .save-container,
  .content_link {
    display: flex;
  }

  .editable-wrapper {
    display: flex;
    background-color: #d0d0d09a;
    padding: var(--default-padding);
    border-radius: 10px;
    flex-grow: 1;
    gap: ${props => (props.editable ? "10px" : 0)};
    flex-direction: column;
    justify-content: center;
    background-color: ${props => (props.editable ? css`#d0d0d09a` : "transparent")};
    border-radius: 10px;
  }

  .arrows {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    margin-right: 5px;
    right: 100%;
    /* padding: 5px; */
    box-sizing: border-box;
    cursor: pointer;
    gap: 10px;
    min-width: 40px;

    svg {
      width: 1.5em;
      height: 1.5em;
    }

    span:active {
      transform: scale(0.9);
    }

    span {
      box-sizing: border-box;
      border-radius: 100%;
      width: fit-content;
      background-color: #686868;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }

    .arrow-up svg {
      margin-bottom: auto;
    }

    .arrow-down svg {
      margin-bottom: top;
    }
  }

  .content-header {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: end;
    padding: 8px;
    cursor: pointer;
    gap: 5px;

    &:active {
      svg {
        transform: scale(0.8);
      }
    }

    svg {
      height: 1em;
      width: 1em;
      transition: transform 0.5s ease;
    }
  }

  .edit-btn {
    box-sizing: border-box;
    border-radius: 100%;
    width: fit-content;
    background-color: #686868;
    padding: 8px;
  }

  .content_title,
  .content_items,
  .content_embed {
    gap: 10px;
    flex-direction: column;
  }

  .edit-media {
    width: 100%;
    font-family: "CodeNext-Book";

    text-align: center;

    a,
    a:visited,
    a:hover,
    a:active {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .content_items {
    /* max-height: ${props => (props.active || props.editcontent ? "10000px" : "0px")}; */
    transition: all 1s;
    overflow: hidden;
    gap: 20px;

    textarea {
      background-color: transparent;
      border: 2px solid #00000075;
      font-family: "CodeNext-Book";
      font-size: 16px;
      border-radius: 5px;
      padding: 5px;
      box-sizing: border-box;
      outline: 0;
    }

    p {
      margin: 0;
    }
  }
  /* .content_embed {
    width: 100%;
    textarea {
      width: 100%;
    }
    * {
      width: 100%;
    }
  }
  .content_download {
    width: 100%;
    gap: 10px;
    justify-content: start;
    flex-wrap: wrap;
    margin: ${props => (props.editable ? "10px 0" : "10px 0 25px 0")};

    a {
      text-decoration: none;
    }
  } */

  .save-container {
    justify-content: end;
    gap: var(--default-padding);
    width: 100%;
  }

  .content_link {
    align-items: center;
    background-color: ${colors.fcb_orange};
    width: fit-content;
    padding: 5px 15px;
    border-radius: 25px;
    box-sizing: border-box;
    color: white;
    font-family: "CodeNext-Book";
    gap: 5px;

    svg {
      height: 1em;
      width: 1em;
    }
  }

  @media screen and (max-width: 700px) {
    width: ${props => (props.editable ? "95%" : "100%")};

    .wrapper {
      min-width: 73vw;
      max-width: 95vw;
    }

    .arrows {
      padding: 5px;
      min-width: 9vw;
      svg {
        width: 1.5em;
        height: 1.5em;
      }
    }

    .content_video video {
      max-height: 100%;
      max-width: 100%;
    }

    .content_download {
      a {
        width: 100%;
      }
    }
  }
`;

const ContentEditableComp = styled(ContentComp)``;

export { ContentComp, ContentEditableComp };
