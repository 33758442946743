import React, {useEffect} from "react";
import {IdeaLink} from "./style";
import {useNavigate} from "react-router";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import {useState} from "react";

const IdeaItem = ({idea, id, ideaid, handleActiveIdea}) => {
  const navigate = useNavigate();
  const [isOn, setIsOn] = useState(idea.is_active);

  useEffect(() => {
    setIsOn(idea.is_active);
  }, [idea]);

  const handleClick = (e) => {
    navigate(`/poll/full_idea/${id}`);
  };

  const handleToggle = (e) => {
    console.log(ideaid);
    handleActiveIdea(ideaid);
  };
  

  return (
    < IdeaLink >
      
      <div onClick={handleClick}>  {idea.title} </div>
    
      <div onClick={handleToggle}>      
       <ToggleButton ideaid={ideaid} ison={isOn} seton={setIsOn} />
       </div>
    </IdeaLink>
  );
};

export default IdeaItem;
