import React from "react";
import styled, { css } from "styled-components";

const ToggleContainer = styled.div`
  display: inline-block;
  width: 52px;
  height: 28px;
  background-color: ${(props) => (props.ison ? "#4CAF50" : "#ccc")};
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const ToggleSwitch = styled.div`
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  margin: 3px;
  transition: transform 0.3s;
  transform: translateX(${(props) => (props.ison ? "25px" : "0")});
`;

const ToggleButton = ({ison, seton, ideaid}) => {

  return (
    <ToggleContainer className="toggle" id={ideaid} ison={ison ? 1 : 0} onClick={()=>{seton(!ison)}}>
      <ToggleSwitch className="toggle" id={ideaid} ison={ison ? 1 : 0} />
    </ToggleContainer>
  );
};

export  {ToggleContainer, ToggleSwitch};
export default ToggleButton;
