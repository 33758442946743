import React, {useEffect, useState, useContext} from "react";
import {Container} from "./style";
import Ballot from "./Ballot";
import {useNavigate, useParams} from "react-router";
import Header from "./Header";
import makeRequest from "../../utils/methods/makeRequest";
import {WebSocketContext} from "../../api/WSContext";

const VoteIdea = ({...props}) => {
  // const [activeRefresh, setactiveRefresh] = useState(false);
  const navigate = useNavigate();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [hasIdea, setHasIdea] = useState(false);
  const [ideia, setIdeia] = useState([]);
  const [myVotes, setMyVotes] = useState([]);
  const [poll_title, setPollTitle] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [poll_kind, setPoll_kind] = useState();

  const params = useParams();
  const ideia_id = params.ideia_id;
  
  useEffect(() => {
    if (message && message.data.method == "notification") {
      //notification(message.data.msg);
    }

    if (message && message.data.method == "get_ideia_ws") {
      setPoll_kind(message.data.poll_kind)
      setIdeia(message.data.ideia);
      setPollTitle(message.data.ideia.poll_title);
    }
    if (message && message.data.method == "navigate") {
      const url = message.data.url
      navigate(url);
    }
    if (message && message.data.method == "list_ideas_ws") {
      var goback = '/poll/vote/'+message.data.poll_title
      message.data.ideias.map(same_idea);
        function same_idea(ideia_check){
          if (ideia_check.id == ideia_id){
            goback=false;
          }
        }
      if(goback){
        navigate(goback);
      }
    }    
  }, [message]);

  const get_ideia_ws = () => {
    sendMessageWS({
      call: "get_ideia_ws",
      data: {
        "ideia_id":ideia_id
      }    });
  };
  
  useEffect(() => {
    get_ideia_ws();
  }, []);

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  return (
    <Container {...props} innerheight={innerHeight}>
      <Header menu={true} title={poll_title} />
      <div className="wrapper">
          <div className="list-items list-ideas">
              <Ballot idea={ideia} votespage={true} myvote={true}  sendMessageWS={sendMessageWS} editvote={true} poll_kind={poll_kind} />
            <br />
        </div>
      </div>
    </Container>
  );
};

export default VoteIdea;
