import React, {useEffect} from "react";
import { Container } from "./style";
import useEditableStore from "../../utils/stores/useEditableStore";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const AdminBar = () => {
  const setEditable = useEditableStore(state => state.setEditable);
  const editable = useEditableStore(state => state.editable);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

 
  const handle_edit = state => {
    editable ? setEditable(false) : setEditable(true);   
  }

  useEffect(() => {
    window.sessionStorage.setItem("editable", editable);
  }, [editable]);


  return (
      <ThemeProvider theme={darkTheme}>
        <Container>
          <div className="toggle-btn-container">
            <FormControlLabel
              control={ <Switch color="success" checked={editable} onChange={handle_edit}/>}
              label={<Typography variant="button"> { editable ? "Visão do editor" : "Visão do colaborador"}</Typography>}
            />
          </div>
          {/* <a href="">EDITAR SITE</a>
          <a href="/paginas-aprovacao">EDITAR PÁGINAS</a> */}
          {/* <a onClick={handleAproval}><Typography variant="button"> APROVAR </Typography></a> */}
        </Container>
      </ThemeProvider>
    )
};

export default AdminBar;
