import styled from "styled-components";

const Container = styled.div`
  background-color: #000000;
  width: 100%;
  font-family: "CodeNext-Book";
  display: flex;
  justify-content: end;
  gap: 20px;
  padding: 5px 15px;
  align-items: center;
  box-sizing: border-box;

  a {
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: 12px;
  }

  .toggle-btn-container {
    color: white;
    width: fit-content;
    margin-right: auto;
  }

`;

export { Container };
