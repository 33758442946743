import { Container } from "./style";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import Header from "./Header";
import makeRequest from "../../utils/methods/makeRequest";
import checkSuperUser from "../../utils/methods/checkSuperUser";
import { methods } from "../../utils/methods/methods";
import {WebSocketContext} from "../../api/WSContext";

const Ranking = ({ idea, ...props }) => {
  const navigate = useNavigate();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const params = useParams();
  const [rankingData, setRankingData] = useState();
  const [juryList, setJuryList] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const poll_title = params.poll_title;

  <span class="material-symbols-outlined"></span>;

  const getRanking = () => {
    sendMessageWS({
      call: "ranking_ws",
      data: {
        "poll_title":poll_title
      },
    });
  };

  useEffect(() => {
    if (message && message.data.method == "ranking_ws") {
      setRankingData(message.data.ranking);
      // var jurys = [];
      // message.data..votes.map((i) => {
      //   jurys.push(i.jury);
      // });
      setJuryList(message.data.juryList);
    }
  }, [message]);


  useEffect(() => {
    if (window.sessionStorage.getItem("user")) {
      var user = JSON.parse(window.sessionStorage.getItem("user"));
      if (!checkSuperUser('ranking', user)) {
        navigate("/");
      }
    }

    getRanking();
  }, []);

  const handleOpenIdeaPage = (id)=>{
    navigate(`/poll/full_idea/${id}`)
  }

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  const transformName = (name) => {
    var string = name+'';
    string = string.split("@")[0];
    string = string.split(".");
    return `${string[0]} ${string[1]}`;
  };

  return (
    <Container innerheight={innerHeight}>
      <Header title={poll_title} />
      <div className="ranking-container">
        <table className="ranking-table">
          <tbody key="tbody">
          <tr key="tr">
            <th className="empty"></th>
            <th className="header-jury">Total</th>
            {/* <th>Volume</th> */}
            {juryList.map((jury, i) => {
              return <th className="header-jury">{transformName(jury.email)} </th>;
            })}
          </tr>

          {rankingData &&
            rankingData.map((idea, i) => {
              return (
                <tr key={i}>
                  <th onClick={(e)=>{handleOpenIdeaPage(idea.id)}} >{idea.title}</th>
                  <th className="votes-cell">{idea.total_votes}</th>
                  {juryList.map((jury, i) => {
                    if (idea.votes) {
                      if (idea.votes[i]) {
                        return (
                          <th className="votes-cell">
                            <span
                              className={`material-symbols-sharp ${methods.getIcon(idea.votes[i].vote)}`}
                            >
                              {methods.getIcon(idea.votes[i].vote)}
                            </span>
                          </th>
                        );
                      }
                    }
                  })}
                </tr>
              );
            })}
            </tbody>
        </table>
      </div>
    </Container>
  );
};

export default Ranking;
