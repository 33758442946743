import styled from "styled-components";

const FooterComp = styled.footer`
    width: 100%;
    left: 0;
    bottom: 0;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
    
    svg{
        height: 1.2em;
        width: 1.2em;
    }
`

export { FooterComp }