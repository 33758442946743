import styled, { css } from "styled-components";

const ButtonComp = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--button-text-size);
  padding: calc(var(--default-padding) / 2) calc(var(--default-padding) * 1);
  font-family: "GothamMedium";
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  width: fit-content;
  border-radius: 10px;
  text-align: center;

  &&:active {
    transform: scale(0.95);
  }

  svg {
    height: 1.2em;
    width: 1.2em;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: center;
    
  }

  ${props =>
    props.theme == "fill" &&
    css`
      background-color: ${props => props.color_set.default};
      border: none;
      color: white;

      &&:hover {
        background-color: ${props => props.color_set.hover};
      }

      svg path {
        stroke: white !important;
      }
    `}

  ${props =>
    props.theme == "outline" &&
    css`
      background-color: transparent;
      border: 2px solid ${props.color_set.default};
      color: ${props => props.color_set.default};

      &&:hover {
        border-color: ${props => props.color_set.hover};
      }

      svg path {
        fill: ${props.color_set.default};
        stroke-width: 2px;
        stroke: ${props.color_set.default};
      }
    `}
`;

export { ButtonComp };
