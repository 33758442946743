import React, {useEffect, useRef, useState, useContext} from "react";
import {HeaderBRCC} from "./style";
import {useNavigate, useParams} from "react-router";
import {useLocation} from "react-router";
import {WebSocketContext} from "../../api/WSContext";
import { Height, Margin } from "@mui/icons-material";
import makeRequest from "../../utils/methods/makeRequest";

const Header = ({menu, ideasLength, title}) => {

  const [votacoes, setVotacoes] = useState(false);

  const [user, setUser] = useState(null);
  const [poll_manager, setPoll_manager] = useState(false);
  const [poll_ranking, setPoll_ranking] = useState(false);  
  const navigate = useNavigate();
  let location = useLocation();
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const params = useParams();


  const [poll_title, setPoll_title] = useState(params.poll_title);


  useEffect(() => {
    if (window.sessionStorage.getItem("user")) {
      var user = JSON.parse(window.sessionStorage.getItem("user"));
      try {
        setUser(JSON.parse(window.sessionStorage.getItem("user")).name);
      } catch (error) {}
    }


  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    setTimeout(() => {
        navigate("/poll/login");
    }, 1000);
  };

  const checkPathname = (path) => {
    if (path == location.pathname) {
      return "active-path";
    }
  };

  useEffect(() => {
    if (message && message.data.method == "get_ideia_ws") {
      setPoll_title(message.data.ideia.poll_title);
    }
    if (message && message.data.method == "get_full_ideia_ws") {
      setPoll_title(message.data.poll_title);
    }    

  }, [message]);




  const getUserData = () => {
    var url = process.env.REACT_APP_SERVER_URL + "/sso/";
    makeRequest(url, "", "get")
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 403) {
          navigate("/vote/login");
        } else {
          var userData = JSON.stringify(data);
          window.sessionStorage.setItem("user", userData);
          window.sessionStorage.setItem("user-token", data.token);
          window.sessionStorage.setItem("poll_manager", data.poll_manager);
          window.sessionStorage.setItem("poll_ranking", data.poll_ranking);

          // setCookie('token', userData.token, 30)
          setPoll_manager(window.sessionStorage.getItem("poll_manager"));
          setPoll_ranking(window.sessionStorage.getItem("poll_ranking"));

          // setTimeout(() => {
          //   navigate("/poll/");
          // }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
   getUserData();
   getList();
  }, []);


  useEffect(() => {
    if (message && message.data.method == "list_polls_ws") {
      if (message.data.polls.length == 1){
        setVotacoes(false);
      }else{
        setVotacoes(true);
      }
    }
  }, [message]);

  const getList = () => {
    sendMessageWS({
      call: "list_polls_ws",
    });
  };





  return (
    <HeaderBRCC >
      <div className="header-fcb">
        <img src="/logos/fcb.png" alt="" />


        {votacoes && <a href={"/poll/list/"}><span>Votações</span> </a> }

        {poll_manager == "true" && poll_title && <a className={checkPathname("/poll/"+poll_title)} href={"/poll/"+poll_title}>Gerenciar</a>}
        {poll_ranking == "true" && poll_title &&  <a className={checkPathname("/poll/ranking/"+poll_title)} href={"/poll/ranking/"+poll_title}>Ranking</a>}

        {poll_title && <a className={`vote-btn-header ${checkPathname("/poll/vote/"+poll_title)}`} href={"/poll/vote/"+poll_title}><span>Votar</span> </a> }
        {poll_title && <a className={checkPathname("/poll/my-votes"+poll_title)} href={"/poll/my-votes/"+poll_title} >Meus Votos</a>}
          
      </div>

      {title && <h2>{title}</h2>}
      {user && <h1 className="header-margin-bottom" >Olá, {user} <small>- <a onClick={handleLogout} >Sair</a></small></h1>}  

        
    </HeaderBRCC>
  );
};

export default Header;

