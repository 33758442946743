import React from "react";
import { Box, TextField } from "@mui/material";

const ListItem = ({ item, elementsList, setElementsList, removeFromList }) => {

  const handleInput = event => {
    var newList = [...elementsList];
    var index = newList.findIndex(elem => elem.id === item.id);
    newList[index][event.target.name] = event.target.value;
    setElementsList(newList);
  };

  return (
    <Box id={`item-${item.id}`} sx={{ display: "flex", gap: "10px", alignItems: "center", width: "100%" }}>
      <TextField onChange={handleInput} fullWidth size="small" defaultValue={item.content_text} name="content_text" placeholder="Item de lista" />
      <TextField onChange={handleInput} fullWidth size="small" defaultValue={item.content_link} name="content_link" placeholder="Link" />
      <p
        className="close-item"
        id={item.id}
        onClick={e => {
          removeFromList(item.id);
        }}>
        Ⅹ
      </p>
      {/* <Close className="close-item" id={id} onClick={removeFromList} /> */}
    </Box>
  );
};

export default ListItem;
