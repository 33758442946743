import React, { useRef } from "react";
import colors from "../../utils/colors.json";
import ContentHub from "../Content/ContentHub";
import Button from "../../components/Button/Button";

const AddContent = ({ params, pagedata, setContents }) => {
  const contentObj = useRef({
    page_path: params.page_path,
    title: "",
    subtitle: "",
    image: "",
    video: "",
    text: "",
    assets: "",
    direct_link: "",
  });

  const handleAddContent = e => {
    var id = pagedata.position_set.length;
    if (id < 0) {
      id = 0;
    } 
    setContents(contents => [
      ...contents,
      <ContentHub autoEdit={true} length={pagedata.position_set.length} datacontent={contentObj.current} key={contents.length - 1} id={id} />,
    ]);
  };

  return (
    <div className="add-content">
      <Button
        className={"add-btn"}
        onClick={handleAddContent}
        icon={"add"}
        label={"Novo conteúdo"}
        color_set={{
          default: colors.fcb_pink,
          hover: colors.fcb_pink_shaddow,
        }}
      />
    </div>
  );
};

export default AddContent;
