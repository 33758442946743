import { DropzoneWrapper, DropzoneInput } from "./style";

const FileInput = ({ onChange, ...props }) => {


  return (
    <DropzoneWrapper
    >
      {/* <DropzoneLabel htmlFor="file-input">
        {dragging ? "Solte" : "Arraste e solte aqui"}
      </DropzoneLabel>
      <br />
      <label htmlFor="">OU</label>
      <br /> */}
      <DropzoneInput type="file" onChange={onChange} />
    </DropzoneWrapper>
  );
};

export default FileInput;
