import React, {useEffect, useState} from "react";
import {Container} from "./style";
import Ballot from "./Ballot";
import {useNavigate} from "react-router";
import Header from "./Header";
import makeRequest from "../../utils/methods/makeRequest";
import useWebSocket from "react-use-websocket";

const BRCCHome = ({...props}) => {
  // const [activeRefresh, setactiveRefresh] = useState(false);
  const navigate = useNavigate();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [hasIdea, setHasIdea] = useState(false);
  const [ideas, setIdeas] = useState([]);
  const [myVotes, setMyVotes] = useState([]);
  // const [authtoken, setAuthToken] = useState(window.sessionStorage.getItem("user-token"));
  // const [socketUrl, setSocketUrl] = useState(
  //   `${process.env.REACT_APP_WSS_SERVER_URL}/BRCC23/receive/${authtoken}`
  // );
  const [socketUrl, setSocketUrl] = useState(
    `${process.env.REACT_APP_WSS_SERVER_URL}/BRCC23/receive`
  );

  const {sendJsonMessage} = useWebSocket(socketUrl, {
    onOpen: () => console.log("Conexão aberta"),
    onClose: () => console.log("Conexão fechada"),
    onMessage: (message) => getFunctionByMessageMethod(JSON.parse(message.data)),
    shouldReconnect: (closeEvent) => true,
  });

  const getFunctionByMessageMethod = (message) => {
    switch (message.method) {
      case "switch_idea_ws":
        updateList(message.data);
        break;
      case "vote_ws":
        updateList(message.data);
        break;
      case "list_ideas_ws":
        updateList(message.data);
        break;
      case "my_votes_ws":
        setMyVotes(message.data);
        break;
      default:
        break;
    }
  };

  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].id === obj.id) {
        return true;
      }
    }
    return false;
  };

  const updateList = (list) => {
    var activeIdeas = [];

    list.map((idea) => {
      if (!idea.is_active) {
        setHasIdea(false);
      } else {
        if (!containsObject(idea, ideas)) {
          idea.new = true;
        }
        activeIdeas.push(idea);
      }
    });

    setIdeas(activeIdeas);
  };

  const getListAndVotes = () => {
    sendJsonMessage({
      call: "list_ideas_ws",
    });
    sendJsonMessage({
      call: "my_votes_ws",
    });
  };

  const getUserData = () => {
    var url = process.env.REACT_APP_SERVER_URL + "/sso/";
    makeRequest(url, "", "get")
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 403) {
          navigate("/vote/login");
        } else {
          var userData = JSON.stringify(data);
          window.sessionStorage.setItem("user", userData);
          window.sessionStorage.setItem("user-token", data.token);
          // setCookie('token', userData.token, 30)
          setTimeout(() => {
            navigate("/vote/brcc");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  useEffect(() => {
    getListAndVotes();
    getUserData();
  }, []);

  useEffect(() => {
    if ((ideas.length - myVotes.length) > 0) {
      setHasIdea(true);
    } else {
      setHasIdea(false);
    }
  }, [ideas, myVotes]);

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  return (
    <Container {...props} innerheight={innerHeight}>
      <Header menu={true} ideasLength={ideas.length - myVotes.length} />
      <div className="wrapper">
        {!hasIdea && <h5 className="notification">Não há idéias para votar, aguarde.</h5>}
        <div className="list">
          <div className="list-items list-ideas">
            {ideas.map((idea, i) => {
              return (
                <Ballot
                  key={idea.id}
                  sendJsonMessage={sendJsonMessage}
                  getListAndVotes={getListAndVotes}
                  idea={idea}
                  myvote={myVotes.find((vote) => vote.ideia === idea.id)}
                />
              );
            })}
            <br />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BRCCHome;
