import { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import { Add, ContactsOutlined } from "@mui/icons-material";
import { ListComp } from "./style";
import ListItem from "./ListItem";

const TextToList = ({ list, contentObj }) => {
  const [elementsList, setElementsList] = useState([]);

  const removeFromList = id => {
    var newList = [...elementsList];
    newList = newList.filter(item => item.id !== id);
    setElementsList(newList);
  };

  useEffect(() => {
    contentObj["text"] = JSON.stringify(elementsList);
  }, [elementsList]);

  useEffect(() => {
    var newList;
    try {
      newList = JSON.parse(list);
    } catch (error) {
      newList = [];
    }
    setElementsList([...newList]);
  }, []);

  return (
    <ListComp>
      {elementsList.map((item, i) => {
        return <ListItem key={item.id} item={item} elementsList={elementsList} setElementsList={setElementsList} removeFromList={removeFromList} />;
      })}
      <Fab
        onClick={() => {
          setElementsList(elementsList => [...elementsList, { id: elementsList.length, content_text: "", content_link: "" }]);
        }}
        variant="extended"
        sx={{ maxWidth: "300px", padding: "0 20px" }}
        size="small"
        color="primary"
        aria-label="add">
        <Add sx={{ mr: 1 }} /> Item de lista
      </Fab>
    </ListComp>
  );
};

export default TextToList;
