import React from "react";
import { ButtonComp } from "./style";
import { icon_set } from "../../utils/icon_set";

const Button = ({
  label = "Botão",
  icon,
  theme = "fill",
  color_set = { default: "gray", hover: "white" },
  ...props
}) => {
  return (
    <ButtonComp theme={theme} color_set={color_set} {...props}>
      {icon_set[icon]}
      <span>{label}</span>
    </ButtonComp>
  );
};

export default Button;
