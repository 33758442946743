import React from "react";
import styled, { css } from "styled-components";
import { toggleMenu } from "../../utils/utils.jsx";

const SandwichMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 18px;
  cursor: pointer;

  ${(props) =>
    props.header !== true &&
    `
    margin-left: auto;`}
  z-index: 5;
`;

const Line = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${(props) => (props.isopen ? "#fff" : "#000")};
  transition: transform 0.3s, opacity 0.3s, background-color 1.5s;
`;

const TopLine = styled(Line)`
  ${({ isopen }) => isopen && "transform: rotate(45deg) translate(5.5px, 5.5px);"}
`;

const MiddleLine = styled(Line)`
  ${({ isopen }) => isopen && "opacity: 0;"}
`;

const BottomLine = styled(Line)`
  ${({ isopen }) => isopen && "transform: rotate(-45deg) translate(5.5px, -5.5px);"}
`;

const MenuButton = ({ isopen, setIsOpen, ...props }) => {
  return (
    <SandwichMenu
      {...props}
      onClick={() => {
        toggleMenu(setIsOpen, isopen);
      }}
    >
      <TopLine isopen={isopen} />
      <MiddleLine isopen={isopen} />
      <BottomLine isopen={isopen} />
    </SandwichMenu>
  );
};

export default MenuButton;
