import React, {useEffect, useState, useContext} from "react";
import {Container} from "./style";
import {useNavigate, useParams} from "react-router";
import Header from "./Header";
import checkSuperUser from "../../utils/methods/checkSuperUser";
import IdeaItem from "./IdeaItem";
import { IdeaLink } from "./style";
import {WebSocketContext} from "../../api/WSContext";

const PollList = ({...props}) => {
  const navigate = useNavigate();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [polls, setPolls] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);


  var jury;
  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });


  useEffect(() => {
    if (message && message.data.method == "list_polls_ws") {
      if (message.data.polls.length == 1){
        navigate(`/poll/vote/${message.data.polls[0].title}`)
      }
      setPolls(message.data.polls);
    }
  }, [message]);

  const getList = () => {
    sendMessageWS({
      call: "list_polls_ws",
    });
  };

  const handleClick = (e) => {
    navigate(`/poll/vote/${e.target.title}`)
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("user")) {
      jury = JSON.parse(window.sessionStorage.getItem("user")).email;
      var user = JSON.parse(window.sessionStorage.getItem("user"));
    } else {
      navigate("/poll/login");
    }
    getList();
  }, []);


  // const transformName = (name) => {
  //   var string = name;
  //   string = string.split("@")[0];
  //   string = string.split(".");
  //   return `${string[0]} ${string[1]}`;
  // };

  return (
    <Container {...props} innerheight={innerHeight}>

      <Header title="Votações" key="header"  />
      <div className="wrapper list-container"  key="wrapper">
        <div className="list-items" key="items" >
          {polls.length > 0 &&
            polls.map((poll, i) => {
              return (
                <IdeaLink onClick={handleClick} title={poll.title} id={poll.id} key={poll.id}  >
                {poll.title} 
              </IdeaLink>
              );
            })}
        </div>

      </div>
    </Container>
  );
};

export default PollList;
