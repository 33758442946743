import React from "react";
import { icon_set } from "../../utils/icon_set.js";
import makeRequest from "../../utils/methods/makeRequest.js";
import getCsrfToken from "../../utils/methods/getCsrfToken.js";

const ContentHeader = ({ content, setOpenEditForm }) => {
  const handleDelete = () => {
    if (window.confirm("Isso apagará o content definivamente, deseja continuar?")) {
      var url = process.env.REACT_APP_SERVER_URL + "/editContent/";
      var deleteData = new FormData();
      deleteData.append("delete", null);
      deleteData.append("content_id", content.content_id);

      getCsrfToken().then(res => {
        makeRequest(url, deleteData, "post", "", res.data)
          .then(res => res.data)
          .then(data => {
            window.location.reload();
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  };

  return (
    <div className="content-header">
      <span className="edit-btn" onClick={handleDelete}>
        {icon_set.delete}
      </span>
      <span
        className="edit-btn"
        onClick={() => {
          setOpenEditForm(true);
        }}>
        {icon_set.edit}
      </span>
    </div>
  );
};

export default ContentHeader;
