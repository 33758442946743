var icon_set = {
  download: (
    <svg width="41" height="49" viewBox="0 0 41 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6785 2V36.1861M20.6785 36.1861L8.66238 24.1839M20.6785 36.1861L32.6945 24.1839M2 47H20.6785H39"
        stroke="black"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  add: (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_221_49)">
        <path
          d="M19 0C15.2422 0 11.5687 1.11433 8.44417 3.20208C5.31964 5.28982 2.88436 8.25722 1.4463 11.729C0.00823243 15.2008 -0.368031 19.0211 0.365088 22.7067C1.09821 26.3924 2.90778 29.7778 5.56498 32.435C8.22218 35.0922 11.6077 36.9018 15.2933 37.6349C18.9789 38.368 22.7992 37.9918 26.271 36.5537C29.7428 35.1156 32.7102 32.6804 34.7979 29.5558C36.8857 26.4313 38 22.7578 38 19C37.9946 13.9626 35.991 9.133 32.429 5.57099C28.867 2.00898 24.0374 0.00544838 19 0V0ZM19 34.8333C15.8685 34.8333 12.8073 33.9047 10.2035 32.1649C7.5997 30.4251 5.5703 27.9523 4.37192 25.0592C3.17353 22.166 2.85998 18.9824 3.47091 15.9111C4.08184 12.8397 5.58982 10.0185 7.80415 7.80414C10.0185 5.58981 12.8397 4.08183 15.9111 3.4709C18.9824 2.85997 22.166 3.17352 25.0592 4.37191C27.9523 5.5703 30.4252 7.59969 32.1649 10.2035C33.9047 12.8073 34.8333 15.8685 34.8333 19C34.8287 23.1978 33.1591 27.2224 30.1908 30.1908C27.2224 33.1591 23.1979 34.8287 19 34.8333V34.8333ZM20.5833 17.4167H26.9167V20.5833H20.5833V26.9167H17.4167V20.5833H11.0833V17.4167H17.4167V11.0833H20.5833V17.4167Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_49">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  edit: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_221_53)">
        <path
          d="M22.94 1.05872C22.2602 0.379917 21.3387 -0.00134277 20.378 -0.00134277C19.4173 -0.00134277 18.4958 0.379917 17.816 1.05872L0 18.8747V23.9987H5.124L22.94 6.18272C23.6186 5.50274 23.9997 4.58135 23.9997 3.62072C23.9997 2.66009 23.6186 1.7387 22.94 1.05872V1.05872ZM4.3 21.9987H2V19.6987L15.31 6.39872L17.61 8.69872L4.3 21.9987ZM21.526 4.76872L19.019 7.27572L16.724 4.97572L19.23 2.47272C19.535 2.16772 19.9487 1.99637 20.38 1.99637C20.8113 1.99637 21.225 2.16772 21.53 2.47272C21.835 2.77772 22.0063 3.19139 22.0063 3.62272C22.0063 4.05405 21.835 4.46772 21.53 4.77272L21.526 4.76872Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_53">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  up: (
    <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 1024 1024" class="icon">
      <path
        fill="#fff"
        d="M488.832 344.32l-339.84 356.672a32 32 0 000 44.16l.384.384a29.44 29.44 0 0042.688 0l320-335.872 319.872 335.872a29.44 29.44 0 0042.688 0l.384-.384a32 32 0 000-44.16L535.168 344.32a32 32 0 00-46.336 0z"
      />
    </svg>
  ),
  down: (
    <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 1024 1024" class="icon" fill="#fff" transform="rotate(180)">
      <g id="SVGRepo_bgCarrier" stroke-width="0" />
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <path
          fill="#fff"
          d="M488.832 344.32l-339.84 356.672a32 32 0 000 44.16l.384.384a29.44 29.44 0 0042.688 0l320-335.872 319.872 335.872a29.44 29.44 0 0042.688 0l.384-.384a32 32 0 000-44.16L535.168 344.32a32 32 0 00-46.336 0z"
        />
      </g>
    </svg>
  ),
  save: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0001 9.99982C11.209 9.99982 10.4356 10.2344 9.77784 10.6739C9.12005 11.1135 8.60736 11.7382 8.30461 12.4691C8.00186 13.2 7.92264 14.0043 8.07698 14.7802C8.23132 15.5561 8.61229 16.2688 9.1717 16.8282C9.73111 17.3877 10.4438 17.7686 11.2198 17.923C11.9957 18.0773 12.8 17.9981 13.5309 17.6953C14.2618 17.3926 14.8865 16.8799 15.326 16.2221C15.7655 15.5643 16.0001 14.7909 16.0001 13.9998C16.0001 12.939 15.5787 11.9215 14.8286 11.1714C14.0784 10.4212 13.061 9.99982 12.0001 9.99982V9.99982ZM12.0001 15.9998C11.6046 15.9998 11.2179 15.8825 10.889 15.6628C10.5601 15.443 10.3037 15.1306 10.1524 14.7652C10.001 14.3997 9.96138 13.9976 10.0386 13.6096C10.1157 13.2217 10.3062 12.8653 10.5859 12.5856C10.8656 12.3059 11.222 12.1154 11.6099 12.0382C11.9979 11.9611 12.4 12.0007 12.7655 12.1521C13.1309 12.3034 13.4433 12.5598 13.6631 12.8887C13.8828 13.2176 14.0001 13.6043 14.0001 13.9998C14.0001 14.5302 13.7894 15.039 13.4143 15.414C13.0393 15.7891 12.5306 15.9998 12.0001 15.9998Z"
        fill="white"
      />
      <path
        d="M19 0H3C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3L0 24H24V5L19 0ZM7 2H17V6H7V2ZM22 22H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H5V8H19V2.828L22 5.828V22Z"
        fill="white"
      />
    </svg>
  ),
  link: (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.7136 14.898L34.3834 4.99909C36.1892 3.31729 40.8 0.819 44.7973 4.28025C49.7938 8.6068 48.9246 13.3383 47.3219 15.4873C46.0398 17.2066 39.2532 25.0192 36.0201 28.7105C33.5102 30.7099 27.5358 33.5005 23.7172 28.668"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M26.0601 37.663L16.7292 46.9414C14.8119 48.4949 10.04 50.6697 6.29057 46.9414C1.60383 42.281 2.79682 37.6207 4.5437 35.5871C5.9412 33.9602 13.2497 26.6335 16.7292 23.1735C19.3709 21.3518 25.5233 18.9792 29 24.0633"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  ),
  delete: (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9999 4H15.8999C15.6678 2.87141 15.0538 1.85735 14.1612 1.12872C13.2686 0.40009 12.1521 0.00145452 10.9999 0L8.99994 0C7.84773 0.00145452 6.73126 0.40009 5.83869 1.12872C4.94612 1.85735 4.33204 2.87141 4.09994 4H0.999939C0.734722 4 0.480369 4.10536 0.292832 4.29289C0.105296 4.48043 -6.10352e-05 4.73478 -6.10352e-05 5C-6.10352e-05 5.26522 0.105296 5.51957 0.292832 5.70711C0.480369 5.89464 0.734722 6 0.999939 6H1.99994V19C2.00153 20.3256 2.52882 21.5964 3.46616 22.5338C4.4035 23.4711 5.67434 23.9984 6.99994 24H12.9999C14.3255 23.9984 15.5964 23.4711 16.5337 22.5338C17.4711 21.5964 17.9984 20.3256 17.9999 19V6H18.9999C19.2652 6 19.5195 5.89464 19.707 5.70711C19.8946 5.51957 19.9999 5.26522 19.9999 5C19.9999 4.73478 19.8946 4.48043 19.707 4.29289C19.5195 4.10536 19.2652 4 18.9999 4V4ZM8.99994 2H10.9999C11.6202 2.00076 12.2251 2.19338 12.7316 2.55144C13.238 2.90951 13.6214 3.41549 13.8289 4H6.17094C6.37852 3.41549 6.76184 2.90951 7.26833 2.55144C7.77481 2.19338 8.37966 2.00076 8.99994 2V2ZM15.9999 19C15.9999 19.7956 15.6839 20.5587 15.1213 21.1213C14.5587 21.6839 13.7956 22 12.9999 22H6.99994C6.20429 22 5.44123 21.6839 4.87862 21.1213C4.31601 20.5587 3.99994 19.7956 3.99994 19V6H15.9999V19Z"
        fill="white"
      />
      <path
        d="M8 17.9994C8.26521 17.9994 8.51957 17.894 8.7071 17.7065C8.89464 17.5189 9 17.2646 9 16.9994V10.9994C9 10.7342 8.89464 10.4798 8.7071 10.2923C8.51957 10.1047 8.26521 9.99939 8 9.99939C7.73478 9.99939 7.48043 10.1047 7.29289 10.2923C7.10536 10.4798 7 10.7342 7 10.9994V16.9994C7 17.2646 7.10536 17.5189 7.29289 17.7065C7.48043 17.894 7.73478 17.9994 8 17.9994Z"
        fill="white"
      />
      <path
        d="M11.9999 17.9994C12.2651 17.9994 12.5195 17.894 12.707 17.7065C12.8945 17.5189 12.9999 17.2646 12.9999 16.9994V10.9994C12.9999 10.7342 12.8945 10.4798 12.707 10.2923C12.5195 10.1047 12.2651 9.99939 11.9999 9.99939C11.7347 9.99939 11.4803 10.1047 11.2928 10.2923C11.1052 10.4798 10.9999 10.7342 10.9999 10.9994V16.9994C10.9999 17.2646 11.1052 17.5189 11.2928 17.7065C11.4803 17.894 11.7347 17.9994 11.9999 17.9994Z"
        fill="white"
      />
    </svg>
  ),
};

export { icon_set };
