import React, { useEffect, useRef, useState, useContext } from "react";
import { Container, TableComments } from "./style";
import Header from "./Header";
import { useParams } from "react-router";
import makeRequest from "../../utils/methods/makeRequest";
import {WebSocketContext} from "../../api/WSContext";

const FullIdea = () => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const params = useParams();
  const [ideia, setIdeia] = useState(null);
  const pageRef = useRef();
  const [message, sendMessageWS] = useContext(WebSocketContext);
  const [poll_title, setPoll_title] = useState(null);

  window.addEventListener("resize", () => {
    setInnerHeight(window.innerHeight);
  });

  const ideia_id = params.ideia_id;

  useEffect(() => {
    if (message && message.data.method == "get_full_ideia_ws") {
      setIdeia(message.data.ideia)
      setPoll_title(message.data.poll_title)
    }
  }, [message]);
  

  const getIdeia = () => {
    sendMessageWS({
      call: "get_full_ideia_ws",
      data: {
        "ideia_id": ideia_id
      }
    });

  };

  const getIcon = (type) => {
    switch (type) {
      case "amei":
        return "favorite";
      case "sim":
        return "check";
      case "nao":
        return "close";
      case "não":
        return "close";
    }
  };

  useEffect(() => {
    getIdeia();
  }, []);

  return (
    <Container ref={pageRef} innerheight={innerHeight}>
      <Header title={poll_title} />
      {ideia && <h3>{ideia.title}</h3>}
      {ideia && <p className="ideia-description">{ideia.description}</p>}
      
      <div className="votes-total">
        {ideia &&
          ideia.votes.map((vote, i) => {
            if (vote.type === "nao" || vote.type === "não") {
              return (
                <p key={i}>
                  <span className="material-symbols-sharp">close</span>NÃO
                  <span className="vote-number"> {vote.total}</span>
                </p>
              );
            }
            if (vote.type === "sim") {
              return (
                <p key={i}>
                  <span className="material-symbols-sharp">check</span>SIM
                  <span className="vote-number"> {vote.total}</span>
                </p>
              );
            }
            if (vote.type === "amei") {
              return (
                <p key={i}>
                  <span className="material-symbols-sharp">favorite</span>AMEI
                  <span className="vote-number"> {vote.total}</span>
                </p>
              );
            }
          })}
      </div>

      <TableComments>
        <h4 className="table-header-comments">
          Comments{" "}
          {/* <a
            onClick={() => {
              printHandler();
            }}
          >
            <span className="material-symbols-sharp">download</span> Download
          </a> */}
        </h4>
        {ideia &&
          ideia.votes.map((vote, i) => {
            return (
              <div className="comments" key={i}>
                {vote.comments.length > 0 && (
                  <h4 className="vote-type">
                    <span className="material-symbols-sharp">{getIcon(vote.type)}</span> {vote.type}
                  </h4>
                )}
                {vote.comments.map((comment, i) => {
                  return (
                    <div key={i} className="comment-line">
                      {comment}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </TableComments>
    </Container>
  );
};

export default FullIdea;
