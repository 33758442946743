import styled from "styled-components";

const Container = styled.div`
  margin-left: -20px;

  @media (max-width: 800px) {
    .search-bar{
      display: none;
    }
  }
  
  /* .search-bar {
    background-color: #ffffff3b;
    display: flex;
    width: 50vw;
    max-width: 800px;
    height: 40px;
    border-radius: 5px;
    border: ${props => (props.theme === "page" ? "0" : "2px")} solid ${props => (props.theme === "page" ? "white" : "00000057")};
    box-sizing: border-box;
    align-items: center;
    overflow: hidden;
    margin: 10px 0;
    border: ${props => props.theme === "top" && `1px solid black`};
    background-color: red;

    input {
      background-color: transparent;
      font-family: "CodeNext-Book";
      padding: 10px;
      height: 100%;
      box-sizing: border-box;
      width: 100%;
      border: none;
      outline: 0;
      font-size: 1.2em;
      color: ${props => (props.theme === "page" ? "white" : "black")};
    }

    span {
      font-size: 2em;
      height: fit-content;
      color: ${props => (props.theme === "page" ? "white" : "00000057")};
    }
  } */
`;

export default Container;
