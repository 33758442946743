import React from "react";
import styled from "styled-components";

const InputWrapper = styled.input`
  border: none;
  box-sizing: border-box;
  border-bottom: 1px solid #00000047;
  padding: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: transparent;
  color: black;


  ${({ variant }) =>
    variant == "title" &&
    `font-family: 'CodeNext-Bold';
    font-weight: 300;
    font-size: 26px;
    width: 100%;
    `}

  ${({ variant }) =>
    variant == "subtitle" &&
    `font-family: 'CodeNext-Bold';
    font-weight: 300;
    font-size: 18px;
    width: 100%;`}
    
  ${({ variant }) =>
    variant == "direct_link" &&
    `font-family: 'CodeNext-Bold';
    font-weight: 300;
    font-size: 16px;
    width: 100%;`}

  ${props => props.file && `z-index: 20;`}
`;

const Input = ({ value, variant, onChange, type = "text", ...props }) => {
  return <InputWrapper variant={variant} type={type} value={value} onChange={onChange} name={variant} {...props} />;
};

export default Input;
