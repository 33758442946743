import axios from "axios";
import getCsrfToken from "./getCsrfToken";
import getCookie from "./GetCookie";

const makeRequest = (url, data, method, onUpload, csrf) => {
  // getCsrfToken();
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.headers.common["X-CSRFToken"] = csrf;
  //axios.defaults.headers.common["X-CSRFToken"] = getCookie("csrftoken");
  axios.defaults.headers.common["X-Frame-Options"] = 'SAMEORIGIN';
  axios.defaults.withCredentials = true;
  axios.defaults.onUploadProgress = onUpload;

  return axios({
    method: method,
    url: url,
    data: data,
    withCredentials: true,
    headers: { 
      "Content-Type": "multipart/form-data"
  },
  });
};

export default makeRequest;
