import styled, { css } from "styled-components";
import colors from "../../utils/colors.json";

const ImageComp = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  color: white;
  align-items: center;
  justify-content: start;
  height: fit-content;
  max-width: 100%;
  min-height: 100px;
  /* padding-top: var(--default-padding); */

  .mask-delete {
    width: 100%;
    height: 100%;
    background-color: #000000aa;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    cursor: pointer;
    transition: .1s ease-in;

    span {
      font-size: 2em;
    }
  }

  .mask-delete:hover {
    opacity: 1;
  }

  ${(props) =>
    props.type == "cover" &&
    css`
      height: 50vh;
      flex-direction: column;
      background: linear-gradient(#00000037, #00000058), ${`url(${props.src})`};
      background-size: cover;
      background-position: center;
      align-items: start;
      justify-content: end;
    `}

    ${(props) =>
      props.pagepath == "aniversariantes" &&
      css`
        height: 150px;
        width: 150px;
        flex-direction: column;
        background: ${`url(${props.src})`};
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      `}

  ${(props) =>
    props.caption &&
    css`
      flex-direction: column;
      color: black;
    `}

    img {
    /* max-height: 450px; */
    max-width: 100%;
    transition: all 1s ease;
  }

  @media screen and (max-width: 700px) {
    height: auto;
    img {
      /* max-height: 100%; */
      max-width: 100%;
      height: auto;
      max-height: unset;
    }
  }
`;

export { ImageComp };
