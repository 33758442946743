import styled, { css } from "styled-components";
import colors from "../../utils/colors.json";

const PageComp = styled.div`
  width: 100%;
  min-height: ${props => props.innerheight + "px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  background-image: linear-gradient(#ffffffa7, #ffffffa7), url("/images/paper-texture.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  .contents-container {
    display: flex;
    width: 50%;
    max-width: 800px;
    gap: ${props => (props.editable ? `var(--default-padding)` : 0)};
    flex-direction: column;

    ${props =>
      props.editable == false &&
      `
        flex-wrap: wrap;
        width: 80%;
    `}


  }

  .title-parabens {
    font-family: "NVRFNSHD-Display";
    font-size: 60px;
    white-space: nowrap;
    text-align: left;
    margin: 0;
    margin-top: 15px;
  }

  .page-title,
  .add-content,
  .btn-container {
    display: flex;
    box-sizing: border-box;
  }

  .page-title {
    align-items: center;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    gap: 20px;
    min-height: 150px;
    background-color: black;
    background-image: url("/images/FCB-Magenta-Color.jpg");
    background-size: 100%;
    background-attachment: fixed;
    color: white;
    padding: var(--default-padding);

    h2 {
      font-size: 1.8em;
    }
  }

  @media screen and (max-width: 700px) {
    .add-content {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
    .contents-container {
      width: 95%;
    }

    .title-parabens {
      font-size: 40px;
    }
  }
  @media (min-width: 700px) and (max-width: 1500px) {
    .contents-container {
      max-width: 700px;
    }


  }
`;

export { PageComp };
