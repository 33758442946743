import styled from "styled-components";
import colors from "../../utils/colors.json";

const MenuComp = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  overflow: scroll;
  width: ${(props) => (props.isopen ? "auto" : "0")};
  max-width: 600px;
  z-index: 3;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: black;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  padding: ${(props) => (props.isopen ? "50px var(--default-padding)" : "0")};
  transition: all 1s ease-in-out;

  * {
    opacity: ${(props) => (props.isopen ? "1" : "0")};
    transition: opacity 0.8s ease;
  }

  h4 {
    margin: 0;
    color: white;

    a {
      text-decoration: underline;
      font-size: 14px;
      color: white;
      font-family: "CodeNext-Book";
      margin-left: 10px;
    }
    margin-bottom: 30px;
  }

  @media screen and (max-width: 700px) {
    width: ${(props) => (props.isopen ? "100%" : "0")};
    padding-top: 0;
    max-width: unset;
  }
`;

const MenuItem = styled.div`
  cursor: pointer;
  padding: var(--default-padding);
  font-family: "NVRFNSHD-Display";
  font-size: 1.4em;
  color: white;
  text-decoration: none;
  box-sizing: border-box;

  &&,
  .subitem-container {
    display: flex;
    flex-direction: column;
  }

  .subitem-container {
    height: fit-content;
    overflow: hidden;
    transition: all 1s ease;
    align-items: center;
    text-decoration: none !important;

    a:-webkit-any-link {
      text-decoration: none !important;
      color: #909090;
      width: fit-content;
    }
  }

  .menu-subitem {
    font-family: "CodeNext-Book";
    font-size: 0.8em;
  }

  .menu-subitem:hover {
    color: ${colors.fcb_blue} !important;
  }

  &:hover {
    .subitem-container {
      overflow: visible;
    }
  }

  &:not(hover) {
    .subitem-container {
      transition: all 1s ease;
    }
  }
`;

export { MenuComp, MenuItem };
