import {useWebSocket} from "react-use-websocket/dist/lib/use-websocket";
import {useState, createContext} from "react";

export const WebSocketContext = createContext(false, null, () => {});

// Make sure to put WebsocketProvider higher up in
// the component tree than any consumer.


export const WebSocketProvider = ({children}) => {
  const [message, setMessage] = useState(null);
  const socketUrl = `${process.env.REACT_APP_WSS_SERVER_URL}`;

  const events = {
    // onOpen: () => console.log(""),
    // onClose: () => console.log("Close WSS"),
    onError: e => console.error(e),
    onMessage: message => {
      setMessage(JSON.parse(message.data));

      if(JSON.parse(message.data).data.status == "invalid jury"){
        window.location.href = `${process.env.REACT_APP_DOMAIN}`;
      }

    },
    shouldReconnect: closeEvent => {
      if (window.location.pathname !== "/login") {
        //return true;
      } else {
        // return false;
      }
    },
    reconnectAttempts: 5,
    reconnectInterval: 500,
    onReconnectStop: number => {
      // window.location.href = "/login";
    },
    heartbeat: {
      message: '{"method": "ping"}',
      // timeout: 3000, // Test
      // interval: 2000, // Test
      timeout: 1803000, // 
      interval: 1800000, // 
    },
  };

  const {sendJsonMessage} = useWebSocket(socketUrl, events);

  const params = [message, sendJsonMessage];

  return <WebSocketContext.Provider value={params}>{children}</WebSocketContext.Provider>;
};
