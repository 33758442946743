import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../utils/colors.json"

const Container = styled.div`
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;

  .progress-bar-inner {
    font-family: 'CodeNext-Bold';
    height: 30px;
    color: black;
    text-align: center;
    line-height: 30px;
    background-color: ${colors["success"]};
  }
`;

const ProgressBar = ({ percentage }) => {
  const [progressWidth, setProgressWidth] = useState(0);
  const [progressSucess, setProgressSucess] = useState(null);

  useEffect(() => {
    setProgressWidth(percentage);
    if(percentage === 100){
        setProgressSucess('Enviado!')
    }
  }, [percentage]);

  return (
    <Container>
      <div className="progress-bar-inner" style={{ width: `${progressWidth}%` }}>
        {!progressSucess && `${progressWidth}%`}
        {progressSucess && progressSucess}
      </div>
    </Container>
  );
};

export default ProgressBar;
