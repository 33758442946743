import styled from "styled-components";

const HeaderComp = styled.div`
  /* position: ${(props) => (props.theme == "page" ? "absolute" : "relative")}; */
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  /* padding: ${(props) => (props.theme == "page" ? "var(--default-padding)" : "0")}; */
  box-sizing: border-box;

  .add-btn {
    width: fit-content !important;
  }

  .header-container  a{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: var(--default-padding);
    box-sizing: border-box;
    flex-wrap: nowrap;
  }
  /*  */
  .header-title {
      color: ${(props) => (props.theme == "page" ? "white" : "black")};
    }

  .menu-btn-container {
    padding-bottom: var(--default-padding);
    box-sizing: border-box;
    z-index: 4;
    width: fit-content;
    width: 100%;

    ${(props) =>
      props.header === true &&
      `
        width: fit-content;

    `}

    div div {
      background-color: ${(props) => props.theme == "page" && "white"};
    }
  }

  .header-logo {
    height: 0.85em;
    width: auto;
  }

  a {
    text-decoration: none;
    color: black;
  }

  h1,
  h4 {
    margin: 0;

    a {
      text-decoration: underline;
      font-size: 12px;
      font-family: "CodeNext-Book";
    }
  }
`;

export { HeaderComp };
