import styled from "styled-components";
import colors from "../../../utils/colors.json";

const Container = styled.div`
  height: 100vh;
  position: relative;
  display: flex;

  .outlet-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: scroll;


    .page {
      padding: 20px;
      box-sizing: border-box;
    }
  }

  .menu-btn-container {
    z-index: 50;
  }
`;

const Header = styled.div`
  width: 100%;
  height: fit-content;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: end;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 2;

  @media (max-width: 800px) {
    padding: 10px;
  }
`;

export {Container, Header};
