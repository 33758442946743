export var methods = {
  idToVote: (id) => {
    switch (id) {
      case 0:
        return "NÃO";
        break;
      case 1:
        return "SIM";
        break;
      case 2:
        return "AMEI";
        break;
    }
  },
  getIcon: (vote) => {
    switch (vote) {
      case 0:
        return "close";
        break;
      case 1:
        return "done";
        break;
      case 2:
        return "favorite";
        break;
    }
  },
};
