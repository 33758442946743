import React, { useEffect, useState } from "react";
import { ContentComp } from "./style.jsx";
import Button from "../Button/Button.jsx";
import Image from "../Image/Image.jsx";
import Input from "../Input/Input.jsx";
import FileInput from "../FileInput/FileInput.jsx";
import useEditableStore from "../../utils/stores/useEditableStore.jsx";
import colors from "../../utils/colors.json";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import TextToList from "../TextToList/TextToList.jsx";

const ContentEditable = ({
  content,
  setContent,
  handleInput,
  contentObj,
  handleSaveContent,
  handleCancelEdit,
  files,
  setFiles,
  images,
  setImages,
  textElement,
  setTextElement,
  video,
  setVideo,
  ...props
}) => {
  const editable = useEditableStore(state => state.editable);
  const [editContent, setEditContent] = useState(false);

  const handleFiles = e => {
    var filename = e.target.files[0].name.split(".");
    var newFileName = filename[0].replace(/(\W+)/gi, "-") + "." + filename[1];
    const newFile = new File([e.target.files[0]], newFileName, { type: e.target.files[0].type });
    contentObj["asset"] = newFile;
  };

  const handleImages = e => {
    setImages(e.target.files[0]);
  };

  // const handleVideo = e => {
  //   setVideo(e.target.files[0]);
  // };

  const handleRemoveImage = e => {
    contentObj["image"] = "delete";
    setContent({
      ...content,
      content_image: "",
    });
  };

  const handleRemoveAsset = e => {
    contentObj["asset"] = "delete";
    setContent({
      ...content,
      content_assets: "",
    });
  };

  // const handleRemoveVideo = e => {};

  const changeTextElement = e => {
    setTextElement(textElement === "Texto" ? "Lista" : "Texto");
  };

  useEffect(() => {
    try {
      var contentJSON = JSON.parse(content.content_text);

      if (contentJSON && typeof contentJSON === "object") {
        setTextElement("Lista");
      }
    } catch (error) {
      setTextElement("Texto");
    }
  }, [content]);

  return (
    <ContentComp active={1} editable={editable ? 1 : 0} editcontent={editContent ? 1 : 0} {...props}>
      <div className="editable-wrapper">
        <Input variant={"title"} extends={"h2"} defaultValue={content.content_title} onChange={handleInput} placeholder={"Titulo"} />
        <Input variant={"subtitle"} extends={"h4"} defaultValue={content.content_subtitle} onChange={handleInput} placeholder={"Subtítulo"} />
        <div className="content_items">
          <FormGroup>
            <FormControlLabel onChange={changeTextElement} control={<Switch color="default" />} label={textElement} />
          </FormGroup>

          {textElement === "Texto" && (
            <textarea
              name={"text"}
              cols="30"
              rows="6"
              onChange={handleInput}
              placeholder="Escreva seu texto aqui..."
              defaultValue={content.content_text}></textarea>
          )}

          {textElement === "Lista" && (
            <TextToList
              contentObj={contentObj}
              onChange={handleInput}
              defaultValue={content.content_text}
              // list={content.content_text !== null ? JSON.parse(content.content_text) : []}
              list={content.content_text}
            />
          )}

          {content.content_image && (
            <Image edit={true} handleRemoveImage={handleRemoveImage} src={process.env.REACT_APP_MEDIA_URL + content.content_image} />
            // <Image edit={true} handleRemoveImage={handleRemoveImage} src={content.content_image} />
          )}

          {!content.content_image && (
            <div className="">
              <h4 className="label-field">Adicionar uma imagem</h4>
              <FileInput onChange={handleImages} />
              {images && <p className="send-file-name">Arquivo: {images.name}</p>}
            </div>
          )}

          {content.content_assets ? (
            <div className="content_download">
              <div>
                <Button
                  icon={"download"}
                  label={content.content_assets.split("/")[content.content_assets.split("/").length - 1]}
                  color_set={{ default: colors.fcb_blue }}
                />
                <div className="edit-media">
                  <a onClick={handleRemoveAsset}>Remover</a>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h4 className="label-field">Adicionar arquivo</h4>
              <FileInput onChange={handleFiles} />
              {files && <p className="send-file-name">Arquivo: {files.name}</p>}
            </div>
          )}
          <div className="content_embed">
            <h3 className="label-field">Incorpore um código</h3>
            <textarea
              name={"embed"}
              cols="30"
              rows="4"
              onChange={handleInput}
              placeholder="Insira seu embed aqui..."
              defaultValue={content.content_embed}></textarea>
          </div>
          <div>
            <h4 className="label-field">Adicione um link</h4>
            <Input
              variant={"direct_link"}
              defaultValue={content.content_direct_link}
              onChange={handleInput}
              placeholder={"https://www.google.com/"}
            />
          </div>
          <div className="save-container">
            <Button
              onClick={handleCancelEdit}
              theme={"outline"}
              color_set={{
                default: colors.fcb_orange,
                hover: colors.fcb_orange_shaddow,
              }}
              label="Descartar"
            />
            <Button
              icon={"save"}
              onClick={handleSaveContent}
              color_set={{
                default: colors.fcb_pink,
                hover: colors.fcb_pink_shaddow,
              }}
              label="Salvar"
            />
          </div>
        </div>
      </div>
    </ContentComp>
  );
};

export default ContentEditable;
