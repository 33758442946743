import React from "react";
import Container from "./style";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const Search = ({ theme }) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <Container theme={theme}>
        {/* <div className="search-bar">
          <input type="text" name="search" />
          <span className="material-symbols-outlined">search</span>{" "}
        </div>
        <div className="results"></div> */}

        {/* <Paper className="search-bar" component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 500 }}>
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Procure no BUSCA..." inputProps={{ "aria-label": "search google maps" }} />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper> */}
      </Container>
    </ThemeProvider>
  );
};

export default Search;
